import styled from 'styled-components';
import { GREY_400, RADIUS_MD } from '../../../theme';

type Size = 'xxl' | 'lg' | 'md' | 'sm' | 'xs';

const SIZES = { xxl: '5.5rem', lg: '4rem', md: '3rem', sm: '2rem', xs: '1.5rem' };

const sizeToRem = ({ $size }: { $size: Size }) => SIZES[$size];

const SkeletonSquareBase = styled.div<{ $size: Size }>`
  height: ${sizeToRem};
  width: ${sizeToRem};
  background: ${GREY_400};
  border-radius: ${RADIUS_MD};
`;
type SkeletonSquareProps = { size?: Size };

const SkeletonSquare = ({ size = 'md' }: SkeletonSquareProps) => {
  return <SkeletonSquareBase $size={size} />;
};

export default SkeletonSquare;
