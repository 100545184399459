import React, { type JSXElementConstructor, type ReactElement, type ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { DANGER_700 } from '../../theme';
import TooltipHelp from '../TooltipHelp';
import { Label, P } from '../Typo';

function filterFalsyValues(val: any[]) {
  return Boolean(val[1]);
}

function reduceKeyValues(obj: Record<string, unknown>, val: any[]) {
  return Object.assign(obj, { [val[0]]: val[1] });
}

export const ErrorText = styled(P)`
  color: ${DANGER_700};
  word-break: break-all;
`;

export const LabelWrapper = styled.div<{ $hideLabels: boolean }>`
  ${({ $hideLabels }) =>
    $hideLabels &&
    css`
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);

      + div {
        margin-top: 0;
      }
    `}
`;

const StyledLabel = styled(Label)`
  display: inline-block;
`;

type FormFieldProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  description?: string;
  error?: ReactNode;
  help?: ReactNode;
  hideLabels?: boolean;
  id?: string;
  label?: string;
  name?: string;
  hasWarning?: boolean;
  required?: boolean;
  useLabelledBy?: boolean;
  smallLabel?: boolean;
};

const FormField = styled(
  ({
    children,
    description,
    error,
    hasWarning,
    help,
    hideLabels = false,
    id,
    label,
    required,
    useLabelledBy,
    smallLabel = false,
    ...props
  }: FormFieldProps) =>
    React.Children.count(children) === 1 ? (
      <div {...props}>
        {label && (
          <LabelWrapper $hideLabels={hideLabels}>
            <StyledLabel
              id={`${id}-label`}
              htmlFor={useLabelledBy ? undefined : id}
              $required={required}
              $small={smallLabel}
            >
              {label}
            </StyledLabel>
            {help && <TooltipHelp header={label} content={help} placement="top" />}
          </LabelWrapper>
        )}

        {description && (
          <P $size={smallLabel ? 'small' : 'medium'} $subdued={smallLabel} id={`${id}-description`}>
            {description}
          </P>
        )}
        {React.cloneElement(
          children,
          [
            ['id', useLabelledBy ? undefined : id],
            ['aria-labelledby', useLabelledBy ? `${id}-label` : undefined],
            ['data-required', required],
            [
              'aria-describedby',
              error ? `${id}-error` : description ? `${id}-description` : undefined,
            ],
            ['variant', error ? 'FAILURE' : undefined],
            ['variant', !error && hasWarning ? 'WARNING' : undefined],
          ]
            .filter(filterFalsyValues)
            .reduce(reduceKeyValues, {}),
        )}
        {error && <ErrorText id={`${id}-error`}>{error}</ErrorText>}
      </div>
    ) : null,
)`
  & > * + * {
    margin-top: 0.25rem;
  }
`;

export default FormField;
