import type React from 'react';
import styled from 'styled-components';
import aq from './images/aq.svg';
import at from './images/at.svg';
import au from './images/au.svg';
import aw from './images/aw.svg';
import ax from './images/ax.svg';
// ”in” is a resvered keyword so we use ”ind” instead for India 👇
import ba from './images/ba.svg';
import be from './images/be.svg';
import bg from './images/bg.svg';
import br from './images/br.svg';
import bw from './images/bw.svg';
import ca from './images/ca.svg';
import ch from './images/ch.svg';
import cn from './images/cn.svg';
import com from './images/com.svg';
import cz from './images/cz.svg';
import de from './images/de.svg';
import dk from './images/dk.svg';
import ee from './images/ee.svg';
import es from './images/es.svg';
import eu from './images/eu.svg';
import fallback from './images/fallback@2x.png';
import fi from './images/fi.svg';
import fr from './images/fr.svg';
import gr from './images/gr.svg';
import hr from './images/hr.svg';
import hu from './images/hu.svg';
import id from './images/id.svg';
import ie from './images/ie.svg';
import ind from './images/in.svg';
import it from './images/it.svg';
import jo from './images/jo.svg';
import jp from './images/jp.svg';
import kr from './images/kr.svg';
import lt from './images/lt.svg';
import lv from './images/lv.svg';
import ma from './images/ma.svg';
import mk from './images/mk.svg';
import mt from './images/mt.svg';
import my from './images/my.svg';
import nl from './images/nl.svg';
import no from './images/no.svg';
import ph from './images/ph.svg';
import pl from './images/pl.svg';
import pt from './images/pt.svg';
import ro from './images/ro.svg';
import rs from './images/rs.svg';
import ru from './images/ru.svg';
import se from './images/se.svg';
import sg from './images/sg.svg';
import si from './images/si.svg';
import sk from './images/sk.svg';
import tr from './images/tr.svg';
import tv from './images/tv.svg';
import ua from './images/ua.svg';
import uk from './images/uk.svg';
import us from './images/us.svg';
import za from './images/za.svg';

export const NAME_MAP = {
  at: 'Austria',
  ax: 'Åland Islands',
  aq: 'Antarctica',
  aw: 'Aruba',
  be: 'Belgium',
  bg: 'Bulgaria',
  bw: 'Botswana',
  ch: 'Switzerland',
  com: 'Global',
  cz: 'Czech Republic',
  de: 'Germany',
  dk: 'Denmark',
  ee: 'Estonia',
  es: 'Spain',
  eu: 'European Union',
  fi: 'Finland',
  'fr-be': 'French (Belgium)',
  fr_be: 'French (Belgium)',
  fr: 'France',
  gr: 'Greece',
  hu: 'Hungary',
  it: 'Italy',
  jp: 'Japan',
  'nl-be': 'Netherlands (Belgium)',
  lt: 'Lithuania',
  lv: 'Latvia',
  nl_be: 'Netherlands (Belgium)',
  nl: 'Netherlands',
  no: 'Norway',
  mk: 'North Macedonia',
  pl: 'Poland',
  pt: 'Portugal',
  ro: 'Romania',
  ru: 'Russia',
  rs: 'Serbia',
  se: 'Sweden',
  sg: 'Singapore',
  tr: 'Turkey',
  tv: 'Tuvalu',
  uk: 'United Kingdom',
  us: 'United States',
  in: 'India',
  kr: 'Korea',
  mt: 'Malta',
  br: 'Brazil',
  ca: 'Canada',
  za: 'South Africa',
  si: 'Slovenia',
  ua: 'Ukraine',
  au: 'Australia',
  hr: 'Croatia',
  cn: 'China',
  sk: 'Slovakia',
  my: 'Malaysia',
  id: 'Indonesia',
  ie: 'Ireland',
  jo: 'Jordan',
  ph: 'Philippines',
  ba: 'Bosnia and Herzegovina',
  ma: 'Morocco',
} as const;

const IMAGE_MAP = {
  at,
  ax,
  aq,
  aw,
  be,
  bg,
  bw,
  ch,
  com,
  cz,
  de,
  dk,
  ee,
  es,
  eu,
  fi,
  fr,
  'fr-be': be,
  frBe: be,
  fr_be: be,
  gr,
  hu,
  it,
  jp,
  lt,
  lv,
  nl,
  'nl-be': be,
  nlBe: be,
  nl_be: be,
  no,
  mk,
  pl,
  pt,
  ro,
  ru,
  rs,
  se,
  sg,
  tr,
  tv,
  uk,
  us,
  in: ind,
  kr,
  mt,
  br,
  ca,
  za,
  si,
  ua,
  au,
  hr,
  cn,
  sk,
  my,
  id,
  ie,
  jo,
  ph,
  ba,
  ma,
};

type FlagCode = keyof typeof NAME_MAP;

export const isFlagCode = (code: string): code is FlagCode => code in NAME_MAP;

type FlagProps = {
  code?: FlagCode;
  size?: string;
  className?: string;
  style?: React.CSSProperties;
};
const size = ({ size = '1rem' }) => size;

const Flag = styled(({ code, className, style }: FlagProps): React.ReactElement => {
  const src = code ? IMAGE_MAP[code] || fallback : fallback;
  const alt = code ? NAME_MAP[code] || code : code;
  return (
    <i className={className} style={style}>
      <img src={src as any} alt={alt} />
    </i>
  );
})`
  flex-shrink: 0;
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;
  border-color: inherit;
  line-height: ${size};
  width: ${size};
  height: ${size};
  & > img {
    position: relative;
    border-radius: 50%;
    width: 100%;
  }
`;

export default Flag;
