import type { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { GREY_100, GREY_400, GREY_800, GREY_900, NEUTRAL_WHITE, PURPLE_700 } from '../../theme';
import { media } from '../utils/media';
import { withLineClamp } from '../utils/withLineClamp';

const Body = styled.tbody`
  background: ${NEUTRAL_WHITE};
  ${media.lessThan('tablet')} {
    display: none;
  }
`;

const Row = styled.tr`
  color: ${GREY_800};
  position: relative;
  &:hover {
    background: ${GREY_100};
  }
`;

export const CellHeadingStyle = css`
  color: ${GREY_900};
  font-weight: 700;
  line-height: 1.5rem;
  font-size: 1rem;

  a,
  button {
    color: ${PURPLE_700};
    text-align: left;
    white-space: nowrap;
  }
`;

const Cell = styled.td`
  &:first-of-type {
    ${withLineClamp(1)}

    ${CellHeadingStyle}
    padding: 1.125rem 0 1.125rem 0.75rem;
    a {
      color: ${PURPLE_700};
      cursor: pointer;
    }
    *:not(:first-child) {
      font-size: 0.8125rem;
      font-weight: 400;
      margin: 0;
      padding: 0;
    }
  }
  & + & {
    font-size: 0.8125rem;
    padding: 1.125rem 1.5rem;
    text-align: right;
  }
`;

const Line = styled.td<{ $avoidLineOverflow?: boolean }>`
  background: ${GREY_400};
  bottom: 0;
  height: 1px;
  padding: 0;
  position: absolute;
  left: ${props => (props.$avoidLineOverflow ? 0 : '-1.625rem')};
  right: ${props => (props.$avoidLineOverflow ? 0 : ' -1.625rem')};
`;

type RowType = {
  row: ReactNode[];
  lastRow: boolean;
  avoidLineOverflow?: boolean;
};

const TableRow: FC<RowType> = ({ row, lastRow, avoidLineOverflow }) => (
  <Row>
    {row.map((cell, i) => (
      <Cell key={i}>{cell}</Cell>
    ))}
    {!lastRow && <Line $avoidLineOverflow={avoidLineOverflow} />}
  </Row>
);

export type TableBodyProps = {
  rows?: ReactNode[][];
  keepLastRowBorder?: boolean;
  avoidLineOverflow?: boolean;
};

const TableBody: FC<TableBodyProps> = ({
  rows = [],
  keepLastRowBorder,
  avoidLineOverflow = false,
}) => (
  <Body>
    {rows?.map((row, i) => (
      <TableRow
        key={i}
        row={row}
        lastRow={!keepLastRowBorder && rows.length - 1 === i}
        avoidLineOverflow={avoidLineOverflow}
      />
    ))}
  </Body>
);

export default TableBody;
