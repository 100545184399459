import type { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { FOCUS_SHADOW, PURPLE_700, RADIUS_SM, RED_700, SIZING_4, SPACING_0_5 } from '../../theme';
import { PlayIcon } from '../Icons/svgsAsFunctions';
import { formatSecondsToHHMMSS } from '../utils/formatSecondsToHHMMSS';
import { lessThan } from '../utils/media';
import { remCalc } from '../utils/remCalc';

const IconWrapper = styled.span<{ $minimizeInMobile: boolean; $hide: boolean }>`
  display: ${props => (props.$hide ? 'none' : 'grid')};
  place-items: center;
  border-radius: 50%;
  border: 0;
  outline: 0;
  background: rgba(24, 22, 70, 0.5);
  backdrop-filter: blur(5.17689px);
  width: ${remCalc(52)};
  height: ${remCalc(52)};
  ${props =>
    props.$minimizeInMobile &&
    css`
      ${lessThan('tablet')} {
        width: ${SIZING_4};
        height: ${SIZING_4};
        svg {
          width: 1rem !important;
        }
      }
    `}
`;

const Thumbnail = styled.button<{
  $image: string;
  $changeAspectRatioInMobile: boolean;
  $showProgress: boolean;
  $progress: number;
  disabled: boolean;
}>`
  display: grid;
  place-items: center;
  position: relative;
  padding: 0;
  width: 100%;
  aspect-ratio: 16 / 9;
  background-image: ${props => `url("${props.$image}")`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  &[disabled] {
    cursor: default;
  }
  ${props =>
    props.$changeAspectRatioInMobile &&
    css`
      ${lessThan('tablet')} {
        aspect-ratio: 4 / 3;
      }
    `}

  [data-whatintent='keyboard'] &:focus {
    outline: none;
    border-color: ${PURPLE_700};
    box-shadow: ${FOCUS_SHADOW};
  }

  &:hover:not([disabled]) ${IconWrapper} {
    background: rgba(24, 22, 70, 0.2);
  }
  ${({ $showProgress, $progress }) =>
    $showProgress &&
    css`
      &::after {
        content: '';
        position: absolute;
        width: ${$progress}%;
        height: 4px;
        left: 0;
        bottom: 0;
        background-color: ${RED_700};
      }
    `}
`;

const Duration = styled.span<{ $minimizeInMobile: boolean; $hide: boolean }>`
  display: ${props => (props.$hide ? 'none' : 'static')};
  position: absolute;
  width: fit-content;
  color: white;
  z-index: 0;
  background: rgba(59, 57, 98, 0.5);
  border-radius: ${RADIUS_SM};

  right: 0.5rem;
  bottom: 0.5rem;
  font-size: 1rem;
  padding: 0.5rem;
  ${props =>
    props.$minimizeInMobile &&
    css`
      ${lessThan('tablet')} {
        font-size: ${remCalc(12)};
        padding: ${SPACING_0_5};
        right: ${SPACING_0_5};
        bottom: ${SPACING_0_5};
      }
    `}
`;

interface VideoThumbnailProps {
  onclick: (id?: string) => void;
  showDuration?: boolean;
  showProgress?: boolean;
  changeAspectRatioInMobile?: boolean;
  disable?: boolean;
  videoData: {
    id: string;
    thumbnail: string;
    duration?: number;
    progressPercentage?: number;
  };
  children?: ReactNode;
  className?: string;
}

const VideoThumbnail: FC<VideoThumbnailProps> = ({
  onclick,
  showDuration = false,
  showProgress = false,
  changeAspectRatioInMobile = false,
  videoData,
  disable = false,
  className,
  children,
}) => {
  return (
    <Thumbnail
      $image={videoData.thumbnail}
      $changeAspectRatioInMobile={changeAspectRatioInMobile}
      onClick={() => onclick(videoData.id)}
      disabled={disable}
      className={className}
      $showProgress={showProgress}
      $progress={videoData.progressPercentage || 0}
    >
      <IconWrapper $minimizeInMobile={changeAspectRatioInMobile} $hide={disable}>
        <PlayIcon color="#fff" width={26} title="Play" style={{ marginLeft: remCalc(4) }} />
      </IconWrapper>
      {showDuration && videoData.duration && (
        <Duration $minimizeInMobile={changeAspectRatioInMobile} $hide={disable}>
          {formatSecondsToHHMMSS(videoData.duration)}
        </Duration>
      )}
      {children}
    </Thumbnail>
  );
};
export default VideoThumbnail;
