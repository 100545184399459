import type { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import { GREY_200, GREY_800, GREY_900 } from '../../theme';
import { media } from '../utils/media';
import type { TableFooterProps } from './TableFooter';
import type { TableHeaderProps } from './TableHeader';

const Footer = styled.tfoot`
  display: none;
  ${media.lessThan('tablet')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 0.75rem;
    background: ${GREY_200};
    margin: 1rem 0.5rem;
    border-radius: 0.25rem;
  }
`;

const Cell = styled.td`
  color: ${GREY_800};
  font-weight: 700;
  display: block;
  font-size: 0.8125rem;
  line-height: 1.75rem;
  text-align: right;
  padding-right: 1rem;
`;

const HeadingCell = styled(Cell)`
  color: ${GREY_900};
  font-weight: 700;
  text-align: left;
  padding-left: 1rem;
  text-transform: uppercase;
`;

const StyledRow = styled.tr`
  padding-top: 2.5rem;
`;

const Header = styled.th`
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
`;

type TableMobileProps = TableFooterProps & TableHeaderProps;

const TableMobileFooter: FC<TableMobileProps> = ({ headings, footerItems }) => {
  const { t } = useTranslation();

  if (!footerItems) {
    return null;
  }

  const firstItemToArray = footerItems && (footerItems[0] as string).split(' ');

  return (
    <Footer>
      <tr>
        <Header>{t('common_total')}:</Header>
        <HeadingCell>{firstItemToArray?.slice(1).join(' ')}</HeadingCell>
        {headings.slice(1)?.map((heading, i) => (
          <HeadingCell key={i}>{heading.text}</HeadingCell>
        ))}
      </tr>
      <StyledRow>
        <Cell>{firstItemToArray[0]}</Cell>
        {footerItems.slice(1)?.map((item, i) => (
          <Cell key={i}>{item}</Cell>
        ))}
      </StyledRow>
    </Footer>
  );
};

export default TableMobileFooter;
