import { useIsInViewport } from '@mnd-frontend/hooks';
import type { UntypedFunction } from '@mnd-frontend/ts';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { getPortalContainer } from '../utils/getPortalContainer';
import { ScreenWrapper } from './styles';

type ScreenProps = {
  background?: string;
  onDismiss?: UntypedFunction;
  onExited?: UntypedFunction;
  show?: boolean;
  escapeToDismiss?: boolean;
  lockedHeight?: boolean;
  children?: React.ReactNode;
  lockFocus?: boolean;
};

const ContentWrapper = styled.div<{ $lockedHeight?: boolean }>`
  ${props =>
    props.$lockedHeight
      ? css`
          height: 100%;
          > div {
            height: 100%;
          }
        `
      : css`
          min-height: 100%;
        `}
  display: flex;
  flex-direction: column;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    > *:only-child {
      flex: 1;
    }
  }
`;

const Screen: React.FC<ScreenProps> = props => {
  const { onDismiss, onExited, show, escapeToDismiss, background, lockFocus = true } = props;
  const [cachedChildren, setCachedChildren] = useState(props.children);
  const screenWrapperRef = useRef<HTMLDivElement>(null);
  const endOfScreenRef = useRef<HTMLDivElement>(null);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  const overflows = !useIsInViewport(endOfScreenRef);

  useEffect(() => {
    const element = screenWrapperRef.current;
    if (!element || !show) return;

    setScrollbarWidth(element.offsetWidth - element.clientWidth);
  }, [show, overflows]);

  if (show && cachedChildren !== props.children) {
    // cache children so we dont update
    // when we are transitioning out.
    setCachedChildren(props.children);
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === 'Escape' ||
        event.key === 'Esc' ||
        event.keyCode === 27 ||
        event.which === 27
      ) {
        onDismiss && onDismiss();
      }
    };

    const scrollPos = window.scrollY;

    if (show) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    }
    if (onDismiss && escapeToDismiss) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      const openOverlays = document.getElementById('app-overlay')?.childElementCount ?? 0;
      if (show && openOverlays < 2) {
        document.body.style.overflow = '';
        document.body.style.height = '';
        window.scrollTo(0, scrollPos);
      }
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [show, onDismiss, escapeToDismiss]);

  const el = getPortalContainer('screen', []);
  if (!el) return null;

  return ReactDOM.createPortal(
    <CSSTransition
      in={show}
      timeout={200}
      classNames="fade"
      mountOnEnter
      unmountOnExit
      onExited={onExited}
      nodeRef={screenWrapperRef}
    >
      <ScreenWrapper
        ref={screenWrapperRef}
        $lockedHeight={props.lockedHeight}
        $background={background}
        style={
          {
            '--scrollbar-width': `${scrollbarWidth}px`,
          } as React.CSSProperties
        }
      >
        <ContentWrapper $lockedHeight={props.lockedHeight}>
          {lockFocus ? <FocusLock returnFocus>{cachedChildren}</FocusLock> : cachedChildren}
        </ContentWrapper>
        <div ref={endOfScreenRef} />
      </ScreenWrapper>
    </CSSTransition>,
    el,
  );
};

export default Screen;
