import { useState } from 'react';
import styled from 'styled-components';
import { GREY_800, SIZING_6, SPACING_1, SPACING_2 } from '../../theme';
import { Chip } from '../Chip';
import Icons from '../Icons';
import { InputWrapper } from '../Input';

const SelectedChipsWrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: ${SPACING_1};
  flex-wrap: wrap;
  padding: ${SPACING_2} ${SIZING_6} ${SPACING_2} ${SPACING_2};
  align-items: flex-start;
`;

const CustomButton = styled.button`
  background: none;
  border: 0;
  color: ${GREY_800};
  flex-shrink: 0;
  font-weight: 300;
  height: ${({ theme }) => theme.sizingButtonHeight};
  line-height: 1.25rem;
  padding: 0;
  text-align: left;
  white-space: nowrap;
  &:hover {
    color: ${({ theme }) => theme.colorTextButtonTextTextButtonOnLightHover};
  }
`;

const Input = styled.input`
  border: none;
  background: transparent;
  flex: 1;
  height: ${({ theme }) => theme.sizingButtonHeight};
  outline: none;
  padding: 0;
  text-overflow: ellipsis;
  &::placeholder {
    color: ${GREY_800};
    font-weight: 300;
  }
  &:focus,
  &:active {
    &::placeholder {
      color: transparent;
    }
  }
`;

const TagsPlaceholderText = styled.span`
  position: absolute;
  left: ${SPACING_2};
  top: 50%;
  transform: translateY(-50%);
  color: ${GREY_800};
  font-weight: 300;
  pointer-events: none;
  transition: color 0.2s ease-out;
`;

type Chip = {
  value: string;
  label: string;
};

type Base<T extends Chip> = {
  label: string;
  onClick?: () => void;
  selectedItems?: T[];
  onRemove?: (id: string) => void;
  dataTestId?: string;
};

type Input = Base<Chip> & {
  type: 'search';
  searchQuery: string;
  onSearchChange: (query: string) => void;
};

type Tags = Base<Chip> & {
  type: 'tags';
  searchQuery: string;
  onSearchChange: (query: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder: string;
};

type Modal = Base<Chip> & {
  type: 'modal';
};

type DropdownList = Base<Chip> & {
  type: 'dropdownList';
  isOpen: boolean;
};

type Props = Modal | Input | DropdownList | Tags;

export const InputChip = ({
  label,
  onClick,
  selectedItems = [],
  onRemove,
  dataTestId,
  type,
  ...props
}: Props) => {
  const [isTagsFocused, setIsTagsFocused] = useState(false);

  const trailingIcon = {
    dropdownList:
      'isOpen' in props ? props.isOpen ? <Icons.ChevronUp /> : <Icons.ChevronDown /> : null,
    search: <Icons.Search />,
    modal: <Icons.Search />,
    tags: null,
  }[type];

  return (
    <InputWrapper hasContent={!!selectedItems} trailingIcon={trailingIcon} skipMargin>
      <SelectedChipsWrapper onClick={() => onClick?.()}>
        {selectedItems?.map(item => (
          <Chip
            key={item.value}
            type="selection"
            onRemove={e => {
              e?.stopPropagation();
              onRemove?.(item.value);
            }}
          >
            {item.label}
          </Chip>
        ))}
        {type === 'search' && (
          <Input
            type="text"
            placeholder={label}
            aria-label={label}
            value={'searchQuery' in props ? props.searchQuery : ''}
            onChange={e =>
              'onSearchChange' in props ? props.onSearchChange(e.target.value) : null
            }
          />
        )}
        {type === 'tags' && (
          <>
            {isTagsFocused &&
              'searchQuery' in props &&
              props.searchQuery.length === 0 &&
              selectedItems.length === 0 && (
                <TagsPlaceholderText>
                  {'placeholder' in props ? props.placeholder : ''}
                </TagsPlaceholderText>
              )}
            <Input
              type="text"
              aria-label={label}
              placeholder={label}
              value={'searchQuery' in props ? props.searchQuery : ''}
              onChange={e =>
                'onSearchChange' in props ? props.onSearchChange(e.target.value) : null
              }
              onKeyDown={e => ('onKeyDown' in props ? props.onKeyDown(e) : null)}
              onClick={onClick}
              onFocus={() => setIsTagsFocused(true)}
              onBlur={() => {
                if ('searchQuery' in props && props.searchQuery.length === 0) {
                  setIsTagsFocused(false);
                }
              }}
            />
          </>
        )}
        {(type === 'modal' || type === 'dropdownList') && (
          <CustomButton type="button" onClick={onClick} data-testid={dataTestId}>
            {label}
          </CustomButton>
        )}
      </SelectedChipsWrapper>
    </InputWrapper>
  );
};
