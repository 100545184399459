import { Select as AriaSelect, ListBox, SelectValue } from 'react-aria-components';
import styled from 'styled-components';
import { GREY_600 } from '../../theme';
import { withLineClamp } from '../utils/withLineClamp';
import {
  type Group,
  IconWrapper,
  OpenButton,
  type Option,
  StyledPopover,
  useDropdownLogic,
} from './common';

const SingleSelectStyles = styled.div`
  .react-aria-Select {
    line-height: 0;
    &[data-disabled] .react-aria-SelectValue {
      color: ${GREY_600};
    }
  }
  .react-aria-SelectValue {
    display: block;
    line-height: 0;
    font-size: 1rem;
    text-align: left;
    max-width: calc(100% - 1.5rem);
    margin-right: 1.75rem;
    white-space: nowrap;
    position: relative;
    ${withLineClamp(1)}

    ${IconWrapper} {
      display: none;
    }
    &[data-placeholder] {
      visibility: hidden;
    }
  }
`;

export interface SingleProps<T> {
  value: T | null;
  onChange: (newValue: T | null) => void;
  options: (Option<T | null> | Group<T | null>)[];
  isMulti?: false;
}
export type SingleSelectProps<T> = Omit<SingleProps<T>, 'isMulti'> & {
  id?: string;
  hasContent?: boolean;
  label?: string;
  labelId: string;
  disabled?: boolean;
  'data-testid'?: string;
};

export const SingleSelect = <T,>({
  value,
  disabled,
  labelId,
  hasContent,
  onChange,
  options,
  label,
  id,
  ...rest
}: SingleSelectProps<T>) => {
  const {
    isKeyCollapseButton,
    onCollapseButtonClick,
    open,
    renderGroupOrItem,
    renderOptions,
    setOpen,
    selectedKeys,
    disabledKeys,
    getValueFromId,
    touchedRef,
  } = useDropdownLogic({ hasContent, options, value });

  return (
    <SingleSelectStyles>
      <AriaSelect
        id={id}
        disabledKeys={disabledKeys}
        isDisabled={disabled}
        placeholder={label}
        selectedKey={selectedKeys[0] ?? null}
        isOpen={open}
        onOpenChange={setOpen}
        onSelectionChange={key => {
          touchedRef.current = true;
          if (typeof key !== 'string') return;
          if (isKeyCollapseButton(key)) {
            const group = onCollapseButtonClick(key);
            if (group.options.some(o => o.value === value)) {
              onChange(null);
            }
            return;
          }
          onChange(getValueFromId(key));
        }}
        aria-labelledby={labelId}
      >
        <OpenButton {...rest}>
          <SelectValue />
        </OpenButton>
        <StyledPopover>
          <ListBox items={renderOptions}>{option => renderGroupOrItem(option)}</ListBox>
        </StyledPopover>
      </AriaSelect>
    </SingleSelectStyles>
  );
};
