import styled, { css } from 'styled-components';
import { GREY_800, GREY_900, GREY_1000, RED_700, TEXT_FONT } from '../../theme';

const baseFontStyle = css`
  color: ${GREY_1000};
  font-family: ${TEXT_FONT};
`;

//
// Headings are used as the heading of each major section on a page in the
// interface.
//
export const Heading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 0;
  ${baseFontStyle}
`;

// H3
// Card components generally use Subheading as their title.
//
export const H3 = styled.h3`
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 0;
  ${baseFontStyle}
`;

// Kicker
//
export const Kicker = styled.h4`
  color: ${RED_700};
  font-family: ${TEXT_FONT};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.5;
  margin: 0;
  text-transform: uppercase;
`;

//
// Card components generally use H4 as their title.
//
export const H4 = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
  ${baseFontStyle}
`;

//
// Legend for Fieldset.
//
export const Legend = styled.legend`
  border: none;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 0;
  ${baseFontStyle}
`;

//
// Text is used for body text and paragraphs.
//
export const P = styled.p<{
  $subdued?: boolean;
  $size?: 'small' | 'medium' | 'large' | 'extraLarge';
  $textAlign?: 'left' | 'right' | 'center';
}>`
  ${({ $size = 'medium' }) =>
    ($size === 'small' && 'font-size: 0.75rem; line-height: 1.125rem') ||
    ($size === 'medium' && 'font-size: 1rem; line-height: 1.5rem') ||
    ($size === 'large' && 'font-size: 2.25rem; line-height: 2.5rem') ||
    ($size === 'extraLarge' && 'font-size: 2.25rem; line-height: 2.5rem')};
  margin: 0;
  font-weight: normal;
  ${baseFontStyle}

  ${({ $textAlign }) =>
    $textAlign &&
    css`
      text-align: ${$textAlign};
    `}
  ${({ $subdued }) =>
    $subdued &&
    css`
      color: ${GREY_800};
    `}
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

//
// Text is used for body text and paragraphs.
//
export const Meta = styled.p`
  color: ${GREY_800};
  font-family: ${TEXT_FONT};
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
`;

//
// Label is used for label texts and item headings
//
//
export const Label = styled.label<{
  $required?: boolean;
  as?: 'label' | 'span' | 'div' | 'h2'; // TODO: Remove usage of h2.
  $small?: boolean;
}>`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  ${baseFontStyle}
  ${({ as }) =>
    as === undefined &&
    css`
      display: block;
    `}
  
    ${({ $small }) =>
      $small &&
      css`
      color: ${GREY_900};
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.2;
    `}
  margin-bottom: 0.25rem;
  ${({ $required }) =>
    $required &&
    css`
      &:after {
        content: '*';
        position: relative;
        top: -0.125rem;
      }
    `}
`;

export const Number = styled.span`
  font-size: 2.25rem;
  font-weight: bold;
  line-height: 1.25;
  ${baseFontStyle}
`;

export const BigNumber = styled.span`
  font-size: 4.5rem;
  font-weight: normal;
  line-height: 1.25;
  ${baseFontStyle}
`;

export const Strong = styled.strong`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25;
  ${baseFontStyle}
`;

export const Light = styled.span`
  color: ${GREY_800};
  font-family: ${TEXT_FONT};
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
`;

export const ScreenReaderOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;
