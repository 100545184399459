import type { UntypedFunction } from '@mnd-frontend/ts';
import type React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import { SHADOW_2, SPACING_0_5, SPACING_3 } from '../../theme';
import { Button } from '../Button';
import media from '../utils/media';

interface DivProps {
  $hasDismiss: boolean;
  $sticky: boolean;
}

export const FacehuggerHeaderStyles = styled.div<DivProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ $hasDismiss }) => ($hasDismiss ? 'space-between' : 'flex-end')};
  padding: 1rem 1.5rem;
  box-shadow: ${SHADOW_2};
  background: white;
  ${props =>
    props.$sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 4; /* Over datepicker stuff */
    `}
`;

const Buttons = styled.div`
  display: flex;
  gap: ${SPACING_3};
  align-items: center;
  ${media.lessThan('phablet')} {
    flex-direction: column;
    gap: ${SPACING_0_5};
  }
`;

type FaceHuggerHeaderProps = {
  optionalActions?: React.ReactNode;
  sticky?: boolean;
} & (
  | {
      dismissLabel?: string;
      onDismiss?: UntypedFunction;
    }
  | {
      dismissButton?: React.ReactNode;
    }
) &
  (
    | {
        onSubmit?: UntypedFunction;
        submitLabel: string;
        submitDisabled?: boolean;
        isSaving?: boolean;
      }
    | {
        submitButton?: React.ReactNode;
      }
  );

const Header: React.FC<FaceHuggerHeaderProps> = props => {
  const { optionalActions, sticky = true } = props;
  const { t } = useTranslation();

  const dismissButton =
    'dismissButton' in props
      ? props.dismissButton
      : 'onDismiss' in props &&
        props.onDismiss && (
          <Button
            configuration="toned"
            type="button"
            data-testid="facehugger_dismiss_btn"
            onClick={props.onDismiss}
          >
            {props.dismissLabel || t('common_close')}
          </Button>
        );

  const submitButton =
    'submitButton' in props
      ? props.submitButton
      : 'submitLabel' in props && (
          <Button
            type="submit"
            onClick={props.onSubmit}
            data-testid="facehugger_submit_btn"
            disabled={props.submitDisabled}
            loading={props.isSaving}
          >
            {props.submitLabel}
          </Button>
        );

  return (
    <FacehuggerHeaderStyles
      $hasDismiss={Boolean(
        ('onDismiss' in props && props.onDismiss) ||
          ('dismissButton' in props && props.dismissButton),
      )}
      $sticky={sticky}
    >
      {dismissButton}
      <Buttons>
        {optionalActions}
        {submitButton}
      </Buttons>
    </FacehuggerHeaderStyles>
  );
};

export default Header;
