import { toBoolean } from '@mnd-frontend/ts';
import { type FC, useState } from 'react';
import styled from 'styled-components';
import { Select } from '..';
import { useTranslation } from '../../contexts/UIContext';
import { GREY_400 } from '../../theme';
import media from '../utils/media';

const Header = styled.thead`
  ${media.greaterThan('tablet')} {
    display: none;
  }
`;

const DropdownWrapper = styled.div`
  &::before {
    content: '';
    background: ${GREY_400};
    top: 0;
    height: 1px;
    left: -1.625rem;
    position: absolute;
    right: -1.625rem;
  }
  position: relative;
  padding: 1rem 1.5rem;
`;

type Sort = { column: number; direction: 'asc' | 'desc' };

type TableMobileHeaderProps = {
  trackSort?: (arg: { label: string; order: 'asc' | 'desc' }) => void;
  defaultSort?: Sort;
  headings: {
    text: string;
    serverSortAscending?: () => void;
    serverSortDescending?: () => void;
  }[];
  onClientSort?: (sort: Sort) => void;
};

const TableMobileHeader: FC<TableMobileHeaderProps> = ({
  defaultSort,
  headings = [],
  onClientSort,
  trackSort,
}) => {
  const { t } = useTranslation();

  const [selectedSort, setSelectedSort] = useState(
    defaultSort ? `${defaultSort.column}-${defaultSort.direction}` : null,
  );

  return (
    <Header>
      <tr>
        <th>
          <DropdownWrapper>
            <Select
              value={selectedSort}
              onChange={value => {
                if (!value) return;

                setSelectedSort(value);
                const [column, direction] = value.split('-');
                const heading = headings[Number(column)];

                trackSort?.({ label: heading.text, order: direction === 'asc' ? 'asc' : 'desc' });
                if (onClientSort) {
                  onClientSort({
                    column: Number(column),
                    direction: direction as Sort['direction'],
                  });
                } else if (direction === 'asc' && heading.serverSortAscending) {
                  heading.serverSortAscending();
                } else if (direction === 'desc' && heading.serverSortDescending) {
                  heading.serverSortDescending();
                }
              }}
              options={headings.flatMap((heading, index) => {
                const keyAsc = `${index}-asc`;
                const keyDesc = `${index}-desc`;

                return [
                  heading.serverSortAscending && {
                    value: keyAsc,
                    label: t('data-table.sort-ascending', { text: heading.text }),
                  },
                  heading.serverSortDescending && {
                    value: keyDesc,
                    label: t('data-table.sort-descending', { text: heading.text }),
                  },
                ].filter(toBoolean);
              })}
            />
          </DropdownWrapper>
        </th>
      </tr>
    </Header>
  );
};

export default TableMobileHeader;
