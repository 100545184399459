import { type ReactNode, useId } from 'react';
import { Dialog as AriaDialog } from 'react-aria-components';
import styled from 'styled-components';
import { UiLibModal } from '../../UiLibModal';
import { useTranslation } from '../../contexts/UIContext';
import {
  DESKTOP_INFO_MODAL_HORIZONTAL_PADDING_LG,
  DESKTOP_INFO_MODAL_HORIZONTAL_PADDING_SM,
  DESKTOP_INFO_MODAL_VERTICAL_GAP,
  DESKTOP_INFO_MODAL_VERTICAL_PADDING_LG,
  DESKTOP_INFO_MODAL_VERTICAL_PADDING_SM,
  GREY_300,
  MOBILE_INFO_MODAL_HORIZONTAL_PADDING_LG,
  MOBILE_INFO_MODAL_HORIZONTAL_PADDING_SM,
  MOBILE_INFO_MODAL_VERTICAL_GAP,
  MOBILE_INFO_MODAL_VERTICAL_PADDING_LG,
  MOBILE_INFO_MODAL_VERTICAL_PADDING_SM,
  SIZING_3,
  SPACING_0_5,
  SPACING_3,
  SPACING_6,
} from '../../theme';
import { Badge } from '../Badge';
import { Button } from '../Button';
import Icons from '../Icons';
import { Stepper, type StepperProps } from '../Stepper';
import { Text, textStyles } from '../Text';
import { UiLibCloseButton } from '../UiLibCloseButton';
import media from '../utils/media';

type DataFields = Record<`data-${string}`, string | boolean>;

type ActionTarget =
  | {
      href: string;
      newTab?: boolean;
      onClick?: () => void;
    }
  | {
      href?: undefined;
      onClick: () => void;
    };

type Action = {
  text: string;
  loading?: boolean;
  disabled?: boolean;
} & DataFields &
  ActionTarget;

type WithoutStepper =
  | {
      textAction?: Action;
      filledAction?: undefined;
      activeIndex?: undefined;
      steps?: undefined;
      confirmAction?: undefined;
    }
  | {
      textAction: Action;
      filledAction?: Action;
      activeIndex?: undefined;
      steps?: undefined;
      confirmAction?: undefined;
    };

type WithStepper = {
  steps: Required<Omit<StepperProps['steps'][number], 'href'>>[];
  activeIndex: StepperProps['activeIndex'];
  confirmAction: Action;
  textAction?: undefined;
  filledAction?: undefined;
};

type Size = 'lg' | 'sm' | undefined;

type SizeProps =
  | {
      size: 'lg';
      media: ReactNode;
    }
  | {
      size: 'sm';
      media?: ReactNode;
    }
  | {
      media?: ReactNode;
    };

export type InfoModalProps = {
  /**
   * Prefer using string for headline, but ReactNode is allowed for `<Trans />`
   */
  headline?: ReactNode;
  /**
   * Prefer using string for description, but ReactNode is allowed for `<Trans />` or things like `<bold>` tags
   */
  description: ReactNode;
  pillText: string;
  open: boolean;
  variant?: 'light' | 'dark';
  onClose: () => void;
} & (WithStepper | WithoutStepper) &
  SizeProps;

const DialogStyles = styled(AriaDialog)<{
  $loading?: boolean;
  $size: Size;
  $variant?: InfoModalProps['variant'];
}>`
  position: relative;
  background: ${props =>
    props.$variant === 'dark'
      ? props.theme.colorBackgroundContainerDark
      : props.theme.colorBackgroundContainerLight};
  --current-background: ${props =>
    props.$variant === 'dark'
      ? props.theme.colorBackgroundContainerDark
      : props.theme.colorBackgroundContainerLight};
  width: ${props =>
    props.$size === 'lg' ? props.theme.sizingInfoModalLgWidth : props.theme.sizingInfoModalSmWidth};
  max-width: 95vw;
  border: 0;
  border-radius: ${props =>
    props.$size === 'lg' ? props.theme.radiusInfoModalLg : props.theme.radiusInfoModalSm};
  display: grid;

  grid-template-columns: ${props =>
    props.$size === 'lg' && '[body] min(100%, 27.5rem) [media] 1fr'};
  grid-template-rows: ${props => props.$size !== 'lg' && '[media] auto [body] auto'};
`;
const CloseButtonPosition = styled.div`
  position: absolute;
  top: ${SPACING_3};
  right: ${SPACING_3};
  line-height: 0;
`;

const Texts = styled.div<{ $dark?: boolean; $size: Size }>`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_0_5};
  h4 {
    color: ${props =>
      props.$dark ? props.theme.colorTextHeadlineOnDark : props.theme.colorTextHeadlinePrimary};
    ${props => props.$size === 'lg' && textStyles.headlineMedium}
  }
  > *:last-child {
    color: ${props =>
      props.$dark ? props.theme.colorTextBodyOnDark : props.theme.colorTextBodyPrimary};
    ${props => props.$size === 'lg' && textStyles.body}
  }
`;

const TopRow = styled.div`
  padding-right: ${SPACING_6};
  height: ${SIZING_3};
  display: flex;
  align-items: center;
`;

const BottomRow = styled.footer<{ $size: Size; $hasStepper: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: ${SPACING_0_5};
  align-items: center;
  flex-direction: ${props => props.$size === 'lg' && !props.$hasStepper && 'row-reverse'};
`;

const Buttons = styled.div<{ $size: Size; $hasStepper: boolean }>`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: ${SPACING_0_5} ${SPACING_3};
  align-items: center;
  flex-direction: ${props => (props.$size === 'lg' && !props.$hasStepper ? 'row-reverse' : 'row')};
`;

const MediaWrapper = styled.div<{ $size: Size }>`
  grid-area: media;
  aspect-ratio: ${props => (props.$size === 'lg' ? '1 / 1' : '16 / 9')};
  height: ${props => props.$size === 'lg' && '100%'};
  position: relative;
  background: ${GREY_300};
  overflow: hidden;
  border-radius: ${props =>
    props.$size === 'lg'
      ? `0 ${props.theme.radiusInfoModalLg} ${props.theme.radiusInfoModalLg} 0`
      : `${props.theme.radiusInfoModalSm} ${props.theme.radiusInfoModalSm} 0 0`};
  ${media.lessThan('phablet')} {
    display: ${props => props.$size === 'lg' && 'none'};
  }
  > * {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const DialogBody = styled.div<{ $size: Size }>`
  grid-area: body;
  padding: ${props =>
    props.$size === 'lg'
      ? `${DESKTOP_INFO_MODAL_VERTICAL_PADDING_LG} ${DESKTOP_INFO_MODAL_HORIZONTAL_PADDING_LG}`
      : `${DESKTOP_INFO_MODAL_VERTICAL_PADDING_SM} ${DESKTOP_INFO_MODAL_HORIZONTAL_PADDING_SM}`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.lessThan('phablet')} {
    padding: ${props =>
      props.$size === 'lg'
        ? `${MOBILE_INFO_MODAL_VERTICAL_PADDING_LG} ${MOBILE_INFO_MODAL_HORIZONTAL_PADDING_LG}`
        : `${MOBILE_INFO_MODAL_VERTICAL_PADDING_SM} ${MOBILE_INFO_MODAL_HORIZONTAL_PADDING_SM}`};
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${DESKTOP_INFO_MODAL_VERTICAL_GAP};
  ${media.lessThan('phablet')} {
    gap: ${MOBILE_INFO_MODAL_VERTICAL_GAP};
  }
`;

export const InfoModal = ({
  headline,
  description,
  pillText,
  media,
  open,
  variant,
  onClose,
  filledAction,
  textAction,
  steps,
  activeIndex,
  confirmAction,
  ...props
}: InfoModalProps) => {
  const size = 'size' in props ? props.size : undefined;
  const { t } = useTranslation();
  const descriptionId = useId();
  const headlineId = useId();

  return (
    <UiLibModal open={open}>
      <DialogStyles
        $size={size}
        aria-labelledby={headline ? headlineId : descriptionId}
        $variant={variant}
      >
        {media ? <MediaWrapper $size={size}>{media}</MediaWrapper> : null}
        <DialogBody $size={size}>
          <div />
          <MainContent>
            <TopRow>
              <Badge variant="informational" text={pillText} />
            </TopRow>
            <CloseButtonPosition>
              {media ? (
                <UiLibCloseButton onClick={onClose} />
              ) : (
                <Button
                  onDark={variant === 'dark'}
                  configuration="text"
                  inline
                  type="button"
                  onClick={onClose}
                  icon={<Icons.Close />}
                  aria-label={t('common_close')}
                />
              )}
            </CloseButtonPosition>
            <Texts $dark={variant === 'dark'} $size={size}>
              {headline ? (
                <Text as="h4" variant="headlineSmall" id={headlineId}>
                  {headline}
                </Text>
              ) : null}
              <Text
                as={typeof description === 'string' ? 'p' : 'div'}
                variant="bodySmall"
                id={descriptionId}
              >
                {description}
              </Text>
            </Texts>
          </MainContent>
          <BottomRow $size={size} $hasStepper={!!steps}>
            {steps ? <Stepper activeIndex={activeIndex} steps={steps} type="mini" /> : <div />}
            <Buttons $size={size} $hasStepper={!!steps}>
              {steps ? (
                <>
                  <Button
                    onDark={variant === 'dark'}
                    configuration="text"
                    type="button"
                    onClick={onClose}
                  >
                    {t('common_skip')}
                  </Button>
                  {activeIndex >= steps.length - 1 ? (
                    <Button
                      onDark={variant === 'dark'}
                      configuration="filled"
                      {...(typeof confirmAction.href === 'string'
                        ? {
                            href: confirmAction.href,
                          }
                        : {
                            type: 'button',
                            onClick: confirmAction.onClick,
                          })}
                      disabled={confirmAction.disabled}
                      loading={confirmAction.loading}
                    >
                      {confirmAction.text}
                    </Button>
                  ) : (
                    <Button
                      onDark={variant === 'dark'}
                      configuration="filled"
                      type="button"
                      onClick={() => {
                        steps[activeIndex + 1]?.onClick();
                      }}
                    >
                      {t('common_next')}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {textAction ? (
                    <Button
                      onDark={variant === 'dark'}
                      configuration="text"
                      {...(typeof textAction.href === 'string'
                        ? {
                            href: textAction.href,
                            newTab: textAction.newTab,
                            onClick: textAction.onClick,
                          }
                        : {
                            type: 'button',
                            onClick: textAction.onClick,
                          })}
                      disabled={textAction.disabled}
                      loading={textAction.loading}
                    >
                      {textAction.text}
                    </Button>
                  ) : null}
                  {filledAction ? (
                    <Button
                      onDark={variant === 'dark'}
                      configuration="filled"
                      {...(typeof filledAction.href === 'string'
                        ? {
                            href: filledAction.href,
                            onClick: filledAction.onClick,
                          }
                        : {
                            type: 'button',
                            onClick: filledAction.onClick,
                          })}
                      disabled={filledAction.disabled}
                      loading={filledAction.loading}
                    >
                      {filledAction.text}
                    </Button>
                  ) : null}
                </>
              )}
            </Buttons>
          </BottomRow>
        </DialogBody>
      </DialogStyles>
    </UiLibModal>
  );
};
