import { type ChangeEventHandler, type MouseEventHandler, type ReactNode, memo } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import {
  DESKTOP_CHIP_HORIZONTAL_GAP_SHORT,
  DESKTOP_CHIP_HORIZONTAL_PADDING_LONG,
  DESKTOP_CHIP_HORIZONTAL_PADDING_SHORT,
  DESKTOP_CHIP_VERTICAL_PADDING_LONG,
  MOBILE_CHIP_HORIZONTAL_GAP_SHORT,
  MOBILE_CHIP_HORIZONTAL_PADDING_SHORT,
  SIZING_3,
} from '../../theme';
import Icons from '../Icons';
import { Spinner } from '../Spinner';
import media from '../utils/media';

const CHECKICON_SIZE = SIZING_3;

const IconWrapper = styled.div<{ $visible?: boolean }>`
  display: grid;
  flex-shrink: 0;
  transition:
    width 0.2s ease-in-out,
    margin-left 0.2s ease-in-out;
  overflow: hidden;
  width: ${({ $visible }) => ($visible ? CHECKICON_SIZE : 0)};
  margin-left: ${({ $visible }) => ($visible ? 0 : 'calc(-1 * var(--chip-gap))')};
`;

const CloseButton = styled.button`
  display: grid;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  &[disabled] {
    cursor: auto;
  }
`;

interface FilterSingle {
  type: 'filterSingle';
  name: string;
  value?: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClick?: undefined;
  children: ReactNode;
}

type AvatarOrIcon =
  | { avatar?: ReactNode; icon?: undefined }
  | { icon?: ReactNode; avatar?: undefined };

interface FilterMultipleCommon {
  type: 'filterMultiple';
  value?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClick?: undefined;
  children: ReactNode;
}

type FilterMultiple = FilterMultipleCommon & AvatarOrIcon;

interface SelectionCommon {
  type: 'selection';
  buttonAriaLabel?: string;
  removeLoading?: boolean;
  onClick?: undefined;
  children: ReactNode;
}

interface SelectionDisabledProps {
  disabled: true;
  onRemove?: MouseEventHandler<HTMLButtonElement>;
}

interface SelectionEnabledProps {
  disabled?: false;
  onRemove: MouseEventHandler<HTMLButtonElement>;
}

type Selection = SelectionCommon & (SelectionDisabledProps | SelectionEnabledProps) & AvatarOrIcon;

interface Suggestion {
  type: 'suggestion';
  length?: 'short' | 'long';
  active?: boolean;
  disabled?: boolean;
  onClick: () => void;
  children: ReactNode;
}

interface ReadOnly {
  type: 'readOnly';
  onClick?: undefined;
  children: ReactNode;
}

type ChipProps = FilterSingle | Selection | Suggestion | ReadOnly | FilterMultiple;

const shortChipWrapperCommonStyles = css`
  display: inline-block;
  max-width: 100%;
  cursor: auto;
  line-height: 0;
  border-radius: ${({ theme }) => theme.radiusChipShort};
  font-size: 1rem;
  font-weight: 500;
`;

const SingleLabel = styled.label<{ $active: boolean }>`
  ${shortChipWrapperCommonStyles}
  background: ${({ theme }) => theme.colorBackgroundChipFilterSingleEnabledDefault};
  user-select: none;
  position: relative;
  cursor: pointer;
  [data-whatintent='keyboard'] &:focus-within,
  &:hover {
    background: ${({ theme }) => theme.colorBackgroundChipFilterSingleEnabledHover};
    color: ${({ theme }) => theme.colorTextChipFilterSingleEnabledHover};
  }
  &:has(input:checked) {
    background: ${({ theme }) => theme.colorBackgroundChipFilterSingleSelectedDefault};
    color: ${({ theme }) => theme.colorTextChipFilterSingleSelectedDefault};
  }
  [data-whatintent='keyboard'] &:focus-within,
  &:hover:has(input:checked) {
    background: ${({ theme }) => theme.colorBackgroundChipFilterSingleSelectedHover};
    color: ${({ theme }) => theme.colorTextChipFilterSingleSelectedHover};
  }
`;

const ShortChipContent = styled.span`
  line-height: 1.5rem;
  height: ${({ theme }) => theme.sizingChipShort};
  padding: 0 ${DESKTOP_CHIP_HORIZONTAL_PADDING_SHORT};
  display: flex;
  align-items: center;
  --chip-gap: ${DESKTOP_CHIP_HORIZONTAL_GAP_SHORT};
  justify-content: center;
  ${media.lessThan('phablet')} {
    --chip-gap: ${MOBILE_CHIP_HORIZONTAL_GAP_SHORT};
    padding: 0 ${MOBILE_CHIP_HORIZONTAL_PADDING_SHORT};
  }
  gap: var(--chip-gap);
`;

const ChipText = styled.span`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const AvatarWrapper = styled.div`
  line-height: 0;
  flex-shrink: 0;
  width: ${SIZING_3};
  height: ${SIZING_3};
  > * {
    width: 100%;
    height: 100%;
  }
`;
const LeadingIconWrapper = styled.div`
  color: ${({ theme }) => theme.colorIconsChipDefault};
  line-height: 0;
  flex-shrink: 0;
  width: ${props => props.theme.sizingIconXxs};
  height: ${props => props.theme.sizingIconXxs};
  > * {
    width: 100%;
    height: 100%;
  }
`;

const MultipleLabel = styled.label<{ $active: boolean }>`
  ${shortChipWrapperCommonStyles}
  color: ${({ theme }) => theme.colorTextChipFilterMultipleDefault};
  background: ${({ theme }) => theme.colorBackgroundChipFilterMultipleDefault};
  user-select: none;
  position: relative;
  cursor: pointer;
  [data-whatintent='keyboard'] &:focus-within,
  &:hover:not(:has(input:disabled)) {
    background: ${({ theme }) => theme.colorBackgroundChipFilterMultipleHover};
    color: ${({ theme }) => theme.colorTextChipFilterMultipleHover};
  }
  &:has(input:checked) {
    outline: 2px solid ${({ theme }) => theme.colorBorderChipActive};
  }
  &:has(input:disabled) {
    cursor: auto;
    color: ${({ theme }) => theme.colorTextChipFilterMultipleDisabled};
    ${LeadingIconWrapper} {
      color: ${({ theme }) => theme.colorTextChipFilterMultipleDisabled};
    }
  }
`;

const SelectionWrapper = styled.aside`
  ${shortChipWrapperCommonStyles}
  color: ${({ theme }) => theme.colorTextChipSelectionDefault};
  background: ${({ theme }) => theme.colorBackgroundChipSelectionDefault};

  &[aria-disabled='true'] {
    color: ${({ theme }) => theme.colorTextChipSelectionDisabled};
    background: ${({ theme }) => theme.colorBackgroundChipSelectionDisabled};

    ${AvatarWrapper},${LeadingIconWrapper} {
      color: ${({ theme }) => theme.colorIconsChipDisabled};
    }
  }
`;

const ReadOnlyWrapper = styled.aside`
  ${shortChipWrapperCommonStyles}
  color: ${({ theme }) => theme.colorTextChipReadOnlyDefault};
  background: ${({ theme }) => theme.colorBackgroundChipReadOnlyDefault};
`;

const SuggestionChipWrapper = styled.button<{ $short?: boolean }>`
  display: inline-block;
  padding: ${({ $short }) =>
    $short ? 0 : `${DESKTOP_CHIP_VERTICAL_PADDING_LONG} ${DESKTOP_CHIP_HORIZONTAL_PADDING_LONG}`};
  border-radius: ${({ theme, $short }) => ($short ? theme.radiusChipShort : theme.radiusChipLong)};
  color: ${({ theme }) => theme.colorTextChipSuggestionDefault};
  background: ${({ theme }) => theme.colorBackgroundChipSuggestionDefault};
  height: ${({ theme, $short }) => $short && theme.sizingChipShort};
  font-size: 1rem;
  border: 0;
  line-height: 1.5rem;
  font-weight: 500;
  max-width: 100%;
  cursor: pointer;
  &[disabled] {
    cursor: auto;
    color: ${({ theme }) => theme.colorTextChipSuggestionDisabled};
  }

  [data-whatintent='keyboard'] &:focus-within,
  &:not([disabled]):hover {
    color: ${({ theme }) => theme.colorTextChipSuggestionHover};
    background: ${({ theme }) => theme.colorBackgroundChipSuggestionHover};
    outline: 0;
  }
  &&&[aria-pressed='true'] {
    outline: 2px solid ${({ theme }) => theme.colorBorderChipActive};
  }
`;

const InputHidden = styled.input`
  appearance: none;
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
`;

const FilterSingleChip = ({ children, name, value, checked, onChange }: FilterSingle) => {
  return (
    <SingleLabel $active={checked}>
      <InputHidden type="radio" checked={checked} onChange={onChange} name={name} value={value} />
      <ShortChipContent>
        <ChipText>{children}</ChipText>
      </ShortChipContent>
    </SingleLabel>
  );
};

const FilterMultipleChip = ({
  children,
  checked,
  icon,
  avatar,
  value,
  disabled,
  onChange,
}: FilterMultiple) => {
  return (
    <MultipleLabel $active={checked}>
      <InputHidden
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        value={value}
      />
      <ShortChipContent>
        {avatar ? <AvatarWrapper>{avatar}</AvatarWrapper> : null}
        {icon ? <LeadingIconWrapper>{icon}</LeadingIconWrapper> : null}
        <ChipText>{children}</ChipText>
        <IconWrapper $visible={checked}>
          <Icons.Check $size="xs" />
        </IconWrapper>
      </ShortChipContent>
    </MultipleLabel>
  );
};

const SelectionChip = ({
  children,
  removeLoading,
  disabled,
  onRemove,
  avatar,
  icon,
  buttonAriaLabel,
}: Selection) => {
  const { t } = useTranslation();
  return (
    <SelectionWrapper aria-disabled={disabled}>
      <ShortChipContent>
        {avatar ? <AvatarWrapper>{avatar}</AvatarWrapper> : null}
        {icon ? <LeadingIconWrapper>{icon}</LeadingIconWrapper> : null}
        <ChipText>{children}</ChipText>
        {!disabled && onRemove ? (
          <CloseButton
            type="button"
            disabled={removeLoading}
            onClick={onRemove}
            aria-label={buttonAriaLabel ?? t('common_delete')}
          >
            {removeLoading ? <Spinner size="xs" /> : <Icons.Close $size="xxs" />}
          </CloseButton>
        ) : null}
      </ShortChipContent>
    </SelectionWrapper>
  );
};
const SuggestionChip = ({ children, disabled, length, active, onClick }: Suggestion) => {
  return (
    <SuggestionChipWrapper
      type="button"
      onClick={onClick}
      aria-pressed={active}
      disabled={disabled}
      $short={length === 'short'}
    >
      {length === 'short' ? (
        <ShortChipContent>
          <ChipText>{children}</ChipText>
          <IconWrapper $visible={active}>
            <Icons.Check $size="xs" />
          </IconWrapper>
        </ShortChipContent>
      ) : (
        children
      )}
    </SuggestionChipWrapper>
  );
};

const ChipImpl = (props: ChipProps) => {
  switch (props.type) {
    case 'filterSingle':
      return <FilterSingleChip {...props} />;
    case 'filterMultiple':
      return <FilterMultipleChip {...props} />;
    case 'selection':
      return <SelectionChip {...props} />;
    case 'readOnly':
      return (
        <ReadOnlyWrapper>
          <ShortChipContent>
            <ChipText>{props.children}</ChipText>
          </ShortChipContent>
        </ReadOnlyWrapper>
      );
    case 'suggestion':
      return <SuggestionChip {...props} />;
  }

  return null;
};

const Chip = memo(ChipImpl);

export default Chip;
