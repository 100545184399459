import { localizeValue } from '@mnd-frontend/utils';
import { animated, config, useSpring } from '@react-spring/web';
import type React from 'react';
import styled from 'styled-components';
import { TEXT_FONT } from '../../../theme';

export const secondsToTimeString = (n: number): string => {
  const h = Math.floor(n / 3600);
  const m = Math.floor((n % 3600) / 60);
  const s = Math.floor((n % 3600) % 60);
  return `${(h ? `${h}h ` : '') + (m || h ? `${m}m ` : '')}${s}s`;
};

type Props = {
  value?: number;
  precision?: BigIntToLocaleStringOptions['maximumFractionDigits'];
  minPrecision?: BigIntToLocaleStringOptions['minimumFractionDigits'];
  abbreviate?: boolean;
  isTime?: boolean;
} & React.HTMLAttributes<HTMLSpanElement>;

const verifiedNumber = (value: string | number): number => {
  const numberValue = Number(value);

  return Number.isNaN(numberValue) ? 0 : numberValue;
};

const Counter = styled(
  ({ value = 0, precision = 0, minPrecision, abbreviate, isTime, className }: Props) => {
    const { number } = useSpring<{ number: number }>({
      number: verifiedNumber(value),
      from: { number: 0 },
      config: { ...config.gentle, clamp: true },
    });
    return (
      <animated.span className={className}>
        {number.interpolate(n =>
          isTime
            ? secondsToTimeString(n)
            : localizeValue(n, { precision, minPrecision, abbrev: abbreviate }),
        )}
      </animated.span>
    );
  },
)`
  font-family: ${TEXT_FONT};
`;

export default Counter;
