import {
  type AnchorHTMLAttributes,
  type ButtonHTMLAttributes,
  type ForwardedRef,
  type MouseEvent,
  type ReactNode,
  forwardRef,
} from 'react';
import styled, { css } from 'styled-components';
import { Spinner } from '..';
import { useNavigate } from '../../contexts/UIContext';
import {
  DANGER_700,
  DANGER_800,
  DANGER_900,
  DESKTOP_BUTTON_HORIZONTAL_GAP,
  DESKTOP_BUTTON_HORIZONTAL_PADDING,
} from '../../theme';

type Configuration = 'toned' | 'filled' | 'text' | 'danger';

const ButtonContent = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${DESKTOP_BUTTON_HORIZONTAL_GAP};
  height: 100%;
`;

const IconWrapper = styled.span`
  line-height: 0;
  flex-shrink: 0;
  width: ${props => props.theme.sizingIconXs};
  height: ${props => props.theme.sizingIconXs};
  > * {
    width: 100%;
    height: 100%;
  }
`;
const ButtonChildrenWrapper = styled.span`
  overflow: hidden;
  font-weight: 500;
  text-overflow: ellipsis;
`;
const SpinnerWrapper = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  line-height: 0;
  transform: translate(-50%, -50%);
`;
const ButtonStyles = styled.button<{
  $configuration: Configuration;
  $loading?: boolean;
  $iconOnly?: boolean;
  $onDark?: boolean;
  $inline?: boolean;
}>`
  cursor: pointer;
  font-weight: normal;
  max-width: 100%;
  padding: 0;
  font-size: 1rem;
  font-family: inherit;
  outline: 0;
  text-decoration: none;
  position: relative;
  border: 0;
  line-height: 1.25rem;
  white-space: nowrap;
  width: ${props => (props.$iconOnly ? props.theme.sizingButtonWidthIconOnly : 'auto')};
  height: ${props => (props.$inline ? 'auto' : props.theme.sizingButtonHeight)};
  flex-shrink: 0;
  display: inline-block;
  * {
    /* Used to make sure target.tagName of events will be BUTTON and not span. */
    pointer-events: none;
  }
  ${ButtonChildrenWrapper} {
    line-height: ${props => props.$iconOnly && 0};
  }
  ${ButtonChildrenWrapper},
  ${IconWrapper} {
    opacity: ${props => (props.$loading ? 0 : 1)};
  }
  [data-whatintent='keyboard'] &:focus,
  &:hover {
    color: var(--button-hover-color);
    background: var(--button-hover-bgcolor);
  }
  &[aria-disabled='true'],
  &:disabled {
    pointer-events: none;
    cursor: default;
  }
  ${props => {
    switch (props.$configuration) {
      case 'toned':
        return css`
          color: ${
            props.$onDark
              ? props.theme.colorTextButtonTextTonedOnDarkDefault
              : props.theme.colorTextButtonTextTonedOnLightDefault
          };
          background: ${
            props.$onDark
              ? props.theme.colorBackgroundButtonTonedOnDarkDefault
              : props.theme.colorBackgroundButtonTonedOnLightDefault
          };
          padding: ${props.$iconOnly ? 0 : `0 ${DESKTOP_BUTTON_HORIZONTAL_PADDING}`};
          border-radius: ${props.theme.radiusButton};

          --spinner-color: ${
            props.$onDark
              ? props.theme.colorBackgroundSpinnerOnDarkDefault
              : props.theme.colorBackgroundSpinnerOnLightDefault
          };
          --button-hover-color: ${
            props.$onDark
              ? props.theme.colorTextButtonTextTonedOnDarkHover
              : props.theme.colorTextButtonTextTonedOnLightHover
          };
          --button-hover-bgcolor: ${
            props.$onDark
              ? props.theme.colorBackgroundButtonTonedOnDarkHover
              : props.theme.colorBackgroundButtonTonedOnLightHover
          };
          &:active {
            color: ${
              props.$onDark
                ? props.theme.colorTextButtonTextTonedOnDarkFocused
                : props.theme.colorTextButtonTextTonedOnLightFocused
            };
            background: ${
              props.$onDark
                ? props.theme.colorBackgroundButtonTonedOnDarkFocused
                : props.theme.colorBackgroundButtonTonedOnLightFocused
            };
          }
          &[aria-disabled='true'],
          &:disabled {
            color: ${
              props.$onDark
                ? props.theme.colorTextButtonTextTonedOnDarkDisabled
                : props.theme.colorTextButtonTextTonedOnLightDisabled
            };
            background: ${
              props.$onDark
                ? props.theme.colorBackgroundButtonTonedOnDarkDisabled
                : props.theme.colorBackgroundButtonTonedOnLightDisabled
            };
          }
        `;
      case 'filled':
        return css`
          color: ${
            props.$onDark
              ? props.theme.colorTextButtonTextFilledOnDarkDefault
              : props.theme.colorTextButtonTextFilledOnLightDefault
          };
          background: ${
            props.$onDark
              ? props.theme.colorBackgroundButtonFilledOnDarkDefault
              : props.theme.colorBackgroundButtonFilledOnLightDefault
          };
          padding: ${props.$iconOnly ? 0 : `0 ${DESKTOP_BUTTON_HORIZONTAL_PADDING}`};
          border-radius: ${props.theme.radiusButton};
          --spinner-color: ${
            props.$onDark
              ? props.theme.colorBackgroundSpinnerOnDarkDefault
              : props.theme.colorBackgroundSpinnerOnLightDefault
          };
          --button-hover-color: ${
            props.$onDark
              ? props.theme.colorTextButtonTextFilledOnDarkHover
              : props.theme.colorTextButtonTextFilledOnLightHover
          };
          --button-hover-bgcolor: ${
            props.$onDark
              ? props.theme.colorBackgroundButtonFilledOnDarkHover
              : props.theme.colorBackgroundButtonFilledOnLightHover
          };
          &:active {
            color: ${
              props.$onDark
                ? props.theme.colorTextButtonTextFilledOnDarkFocused
                : props.theme.colorTextButtonTextFilledOnLightFocused
            };
            background: ${
              props.$onDark
                ? props.theme.colorBackgroundButtonFilledOnDarkFocused
                : props.theme.colorBackgroundButtonFilledOnLightFocused
            };
          }
          &[aria-disabled='true'],
          &:disabled {
            color: ${
              props.$onDark
                ? props.theme.colorTextButtonTextFilledOnDarkDisabled
                : props.theme.colorTextButtonTextFilledOnLightDisabled
            };
            background: ${
              props.$onDark
                ? props.theme.colorBackgroundButtonFilledOnDarkDisabled
                : props.theme.colorBackgroundButtonFilledOnLightDisabled
            };
          }
        `;
      case 'danger':
        return css`
          color: white;
          background: ${DANGER_700};
          padding: ${props.$iconOnly ? 0 : `0 ${DESKTOP_BUTTON_HORIZONTAL_PADDING}`};
          border-radius: ${props.theme.radiusButton};
          --spinner-color: ${DANGER_700};
          --button-hover-color: white;
          --button-hover-bgcolor: ${DANGER_800};
          &:active {
            color: white;
            background: ${DANGER_900};
          }
          &[aria-disabled='true'],
          &:disabled {
            color: ${
              props.$onDark
                ? props.theme.colorTextButtonTextFilledOnDarkDisabled
                : props.theme.colorTextButtonTextFilledOnLightDisabled
            };
            background: ${
              props.$onDark
                ? props.theme.colorBackgroundButtonFilledOnDarkDisabled
                : props.theme.colorBackgroundButtonFilledOnLightDisabled
            };
          }
        `;
      case 'text':
        return css`
          color: ${
            props.$onDark
              ? props.theme.colorTextButtonTextTextButtonOnDarkDefault
              : props.theme.colorTextButtonTextTextButtonOnLightDefault
          };
          width: auto;
          background: none;
          --spinner-color: ${
            props.$onDark
              ? props.theme.colorBackgroundSpinnerOnDarkDefault
              : props.theme.colorBackgroundSpinnerOnLightDefault
          };
          --button-hover-color: ${
            props.$onDark
              ? props.theme.colorTextButtonTextTextButtonOnDarkHover
              : props.theme.colorTextButtonTextTextButtonOnLightHover
          };
          &:active {
            color: ${
              props.$onDark
                ? props.theme.colorTextButtonTextTextButtonOnDarkFocused
                : props.theme.colorTextButtonTextTextButtonOnLightFocused
            };
          }
          &[aria-disabled='true'],
          &:disabled {
            color: ${
              props.$onDark
                ? props.theme.colorTextButtonTextTextButtonOnDarkDisabled
                : props.theme.colorTextButtonTextTextButtonOnLightDisabled
            };
          }
        `;
    }
  }}
`;

interface CommonButtonProps {
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  disabled?: boolean;
  id?: string;
  loading?: boolean;
  configuration?: Configuration;
  onDark?: boolean;
}

interface ButtonStyledProps {
  configuration?: Configuration;
}

interface TextButtonProps {
  inline?: boolean;
  configuration: 'text';
}

type ChildrenContent =
  | { icon: ReactNode; 'aria-label': string; children?: undefined }
  | { children: string | number; icon?: undefined };

interface RenderLinkProps<T = HTMLAnchorElement> {
  href: string;
  newTab?: boolean;
  replace?: boolean;
  download?: string;
  type?: undefined;
  onClick?: (event: MouseEvent<T>) => void;
}

interface SubmitButtonProps<T = HTMLButtonElement> {
  onClick?: (event: MouseEvent<T>) => void;
  type: 'submit';
}

interface RegularButtonProps<T = HTMLButtonElement> {
  onClick: (event: MouseEvent<T>) => void;
  type: 'button';
}

export type ButtonProps<T> = CommonButtonProps &
  ChildrenContent &
  (TextButtonProps | ButtonStyledProps) &
  (RenderLinkProps<T> | SubmitButtonProps<T> | RegularButtonProps<T>);

const emptyObject = {} as const;

type SpreadProps =
  // Could not figure out correct type for 'as' after styled component v6 upgrade
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ({ as: any } & (
      | AnchorHTMLAttributes<HTMLAnchorElement>
      | ButtonHTMLAttributes<HTMLButtonElement>
      | typeof emptyObject
    ))
  | typeof emptyObject;

const InnerButton = <T,>(
  {
    onClick,
    disabled,
    loading,
    trailingIcon,
    leadingIcon,
    id,
    configuration = 'filled',
    ...restProps
  }: ButtonProps<T>,
  ref: ForwardedRef<T>,
) => {
  const navigate = useNavigate();
  let props: SpreadProps = {};
  Object.entries(restProps).forEach(([key, value]) => {
    if (key.startsWith('aria-') || key.startsWith('data-')) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (props as any)[key] = value;
    }
  });
  let newTab: boolean | undefined;
  const functionallyDisabled = disabled || loading;
  if ('href' in restProps && restProps.href) {
    const href = restProps.href;
    newTab = restProps.newTab;

    const common: typeof props = {
      'aria-disabled': functionallyDisabled,
      tabIndex: functionallyDisabled ? -1 : undefined,
      'aria-label': 'aria-label' in restProps ? restProps['aria-label'] : undefined,
    };
    if (newTab) {
      props = {
        ...props,
        as: 'a',
        href,
        target: '_blank',
        rel: 'noreferrer noopener',
        ...common,
      };
    } else if (/^[a-z]{1,10}:/.test(href)) {
      props = {
        ...props,
        as: 'a',
        href,
        download: restProps.download,
        rel: 'noreferrer noopener',
        ...common,
      };
    } else {
      const originalOnClick = onClick;
      onClick = e => {
        originalOnClick?.(e);
        if (!e.defaultPrevented) {
          e.preventDefault();
          navigate(href, { replace: restProps.replace });
        }
      };
      props = {
        ...props,
        as: 'a',
        href: href,
        ...common,
      };
    }
  } else {
    props = {
      ...props,
      type: restProps.type,
      disabled: functionallyDisabled,
      'aria-label': 'aria-label' in restProps ? restProps['aria-label'] : undefined,
    };
  }

  const iconOnly = 'icon' in restProps && restProps.icon;

  return (
    <ButtonStyles
      $configuration={configuration}
      ref={ref}
      id={id}
      $onDark={'onDark' in restProps ? restProps.onDark : undefined}
      $inline={'inline' in restProps ? restProps.inline : undefined}
      {...props}
      onClick={onClick}
      $loading={loading}
      $iconOnly={iconOnly}
    >
      <ButtonContent as="div">
        {leadingIcon && <IconWrapper>{leadingIcon}</IconWrapper>}
        <ButtonChildrenWrapper>
          {iconOnly ? restProps.icon : restProps.children}
        </ButtonChildrenWrapper>
        {trailingIcon && <IconWrapper>{trailingIcon}</IconWrapper>}
      </ButtonContent>
      {loading && (
        <SpinnerWrapper>
          <Spinner size="sm" />
        </SpinnerWrapper>
      )}
    </ButtonStyles>
  );
};

export const Button = forwardRef(InnerButton) as <T>(
  props: ButtonProps<T> & { ref?: ForwardedRef<T> },
) => ReturnType<typeof InnerButton<T>>;
