import { type ChangeEventHandler, type ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { SPACING_1 } from '../../theme';
import Icons from '../Icons';

interface CheckboxCommonInputProps {
  id?: string;
  disabled?: boolean;
  label?: ReactNode;
  onChange: ChangeEventHandler<HTMLInputElement>;
  'aria-label'?: string;
}

export type CheckboxState = 'checked' | 'indeterminate' | 'unchecked';

export type CheckboxInputProps = CheckboxCommonInputProps &
  ({ state: CheckboxState; checked?: undefined } | { checked: boolean; state?: undefined });

const CheckboxWrapper = styled.div<{ $withLabel?: boolean }>`
  position: relative;

  // Adjustment to center align with first row ((line height - visual height) / 2)
  top: ${props => props.$withLabel && '0.15625rem;'};
`;

export const CheckboxVisual = styled.div`
  height: ${props => props.theme.sizingCheckboxHeight};
  width: ${props => props.theme.sizingCheckboxWidth};
  background: ${props => props.theme.colorBackgroundCheckboxFalseEnabled};
  border: 1px solid ${props => props.theme.colorBorderCheckboxFalseEnabled};
  border-radius: ${props => props.theme.radiusCheckbox};
  transition: all 200ms ease;
  color: white;
  > * {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0.75rem;
    height: 0.75rem;
    line-height: 0;
    path {
      stroke: white;
      vector-effect: non-scaling-stroke;
    }
  }
`;
const CheckboxHidden = styled.input`
  position: absolute;
  z-index: 1;
  left: 0;

  /*
    Hidden checkbox is only used as a hitarea so we want to make sure that it
    is always hidden and not effected by css resets overrides.
  */
  appearance: none !important;
  opacity: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  width: 100% !important;
  height: 100% !important;
  outline: none !important;

  &:hover:not(:disabled) + ${CheckboxVisual} {
    border-color: ${props => props.theme.colorBackgroundCheckboxTrueHover};
    background: ${props => props.theme.colorBackgroundCheckboxTrueHover};
  }

  + ${CheckboxVisual} {
    border-color: ${props => props.theme.colorBackgroundCheckboxTrueEnabled};
    background-color: ${props => props.theme.colorBackgroundCheckboxTrueEnabled};
  }

  &:focus + ${CheckboxVisual} {
    border-color: ${props => props.theme.colorBackgroundCheckboxTrueHover};
    background: ${props => props.theme.colorBackgroundCheckboxTrueHover};
  }

  &[aria-checked='false'] {
    + ${CheckboxVisual} {
      background-color: transparent;
      border-color: ${props => props.theme.colorBorderCheckboxFalseEnabled};
    }
    &:focus + ${CheckboxVisual} {
      border-color: ${props => props.theme.colorBorderCheckboxFalseHover};
      background: ${props => props.theme.colorBackgroundCheckboxFalseHover};
    }
    &:hover:not(:disabled) + ${CheckboxVisual} {
      border-color: ${props => props.theme.colorBorderCheckboxFalseHover};
      background: ${props => props.theme.colorBackgroundCheckboxFalseHover};
    }
  }

  &:disabled {
    &[aria-checked='false'] + ${CheckboxVisual} {
      background-color: ${props => props.theme.colorBackgroundCheckboxFalseDisabled};
      border-color: ${props => props.theme.colorBorderCheckboxFalseDisabled};
    }

    &:not([aria-checked='false']) + ${CheckboxVisual} {
      // Both indeterminate and checked
      border-color: ${props => props.theme.colorBackgroundCheckboxTrueDisabled};
      background-color: ${props => props.theme.colorBackgroundCheckboxTrueDisabled};
    }
  }
`;

const Label = styled.label<{ $disabled?: boolean }>`
  display: flex;
  max-width: 100%;
  gap: ${SPACING_1};
  align-items: flex-start;
  user-select: none;
  cursor: ${props => (props.$disabled ? 'default' : 'pointer')};
  ${CheckboxHidden} {
    cursor: ${props => (props.$disabled ? 'default' : 'pointer')};
  }
  line-height: 0;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${props =>
    props.$disabled ? props.theme.colorTextLabelDisabled : props.theme.colorTextBodyPrimary};
`;

const LabelText = styled.span`
  line-height: 1.3125rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ARIA_CHECKED = {
  checked: 'true',
  indeterminate: 'mixed',
  unchecked: 'false',
} as const;

export const Checkbox = ({
  label,
  disabled,
  state: propsState,
  checked: propsChecked,
  ...props
}: CheckboxInputProps) => {
  const state = (propsState ?? (propsChecked ? 'checked' : 'unchecked')) as CheckboxState;
  const input = useRef<HTMLInputElement>(null);
  const indeterminate = state === 'indeterminate';
  const checked = state === 'checked';

  useEffect(() => {
    if (!input.current || indeterminate === undefined) return;

    input.current.indeterminate = indeterminate;
  }, [input, indeterminate]);

  return (
    <Label $disabled={disabled}>
      <CheckboxWrapper $withLabel={!!label}>
        <CheckboxHidden
          {...props}
          ref={input}
          type="checkbox"
          aria-checked={ARIA_CHECKED[state]}
          checked={checked}
          disabled={disabled}
        />
        <CheckboxVisual>
          <Icons.Check aria-label="checked" style={{ opacity: checked ? 1 : 0 }} />
          <Icons.Minus aria-label="mixed" style={{ opacity: indeterminate ? 1 : 0 }} />
        </CheckboxVisual>
      </CheckboxWrapper>
      {label ? <LabelText>{label}</LabelText> : null}
    </Label>
  );
};
