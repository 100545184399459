import React, { type ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import { DANGER_700, TEXT_FONT } from '../../theme';
import { Button } from '../Button';
import Icons from '../Icons';
import Panel, { type PanelProps } from '../Panel';
import Section from './components/CardSection';
import Header from './components/Header';

export type CardComponentProps = {
  action?: React.ReactNode;
  children?: React.ReactNode;
  /** Title content for the card */
  title?: any;

  /** Description of the card content* */
  description?: string | React.ReactNode;

  /** Auto wrap content in section */
  sectioned?: boolean;

  /** Display card and content as loading */
  loading?: boolean;

  /** Experimental * */
  expandable?: boolean;

  tableCard?: boolean;
};

type CardProps = {
  overflowVisible?: boolean;
  error?: boolean;
  marginBottom?: boolean;
} & CardComponentProps;

const CardComponent = ({
  action,
  children,
  sectioned,
  title,
  description,
  expandable,
  loading,
  error,
  marginBottom,
  tableCard,
  overflowVisible,
  ...props
}: CardProps & PanelProps & { children?: ReactNode }) => {
  const [show, setShow] = React.useState(false);
  const { t } = useTranslation();

  const content = React.useMemo(
    () =>
      sectioned
        ? (React.Children.map(children, child => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return child && <Section>{child as any}</Section>;
          }) as ReactNode)
        : children,
    [sectioned, children],
  );

  const toggle = React.useMemo(
    () =>
      expandable ? (
        <Button
          icon={show ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
          aria-label={show ? t('card_action-collapse') : t('card_action-expand')}
          onClick={() => setShow(!show)}
          configuration="text"
          type="button"
        />
      ) : undefined,
    [expandable, show, t],
  );

  return (
    <Panel $loading={loading} {...props}>
      {title || description ? (
        <Header
          title={title}
          description={description}
          action={action || toggle}
          $tableCard={tableCard}
        />
      ) : null}
      {expandable && !show ? undefined : content}
    </Panel>
  );
};

const Card = styled(CardComponent)<CardProps>`
  font-family: ${TEXT_FONT};
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25;
  position: relative;
  height: 100%;
  ${props =>
    props.error &&
    css`
      border: 1px solid ${DANGER_700};
    `};
  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: 1.5rem;
    `}
  ${({ overflowVisible }) =>
    overflowVisible
      ? css`
          overflow: visible;
          /*
            Hack to disable colpasing child margins until 
            we drop ie11 and can use display: flow-root
          */
          &:before {
            content: '';
            clear: both;
            display: table;
          }
          /*
            Hack to disable colpasing child margins until in firefox
          */
          display: inline-block;
          width: 100%;
        `
      : css`
          overflow: hidden;
        `};
`;

export default Card;
