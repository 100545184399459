import type { AriaRole, CSSProperties } from 'react';
import { cssWidthAndHeight } from '../../utils/cssWidthAndHeight';

interface SvgProps {
  color?: string;
  width?: number;
  height?: number;
  style?: CSSProperties;
  title?: string;
  role?: AriaRole;
}

export const PlayIcon = ({
  color = 'currentColor',
  width = 24,
  height,
  style,
  title,
  role = 'img',
}: SvgProps) => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={{ ...cssWidthAndHeight(width, height), ...style }}
    viewBox="0 0 24 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role={role}
  >
    {title && <title>Play</title>}
    <path d="M24 14L6.02284e-07 27.8564L1.81365e-06 0.143593L24 14Z" fill={color} />
  </svg>
);
