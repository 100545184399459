import { type ReactNode, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import { DefaultTheme, SPACING_0_5, SPACING_1, SPACING_3 } from '../../theme';
import { Badge } from '../Badge';
import { Button } from '../Button';
import Icons from '../Icons';
import { Text, type TextProps } from '../Text';
import media from '../utils/media';
import queries from './container-queries.module.css';

export interface GuideBannerProps {
  onClose?: () => void;
  action?:
    | {
        onClick?: () => void;
        text: string;
        href: string;
        newTab?: boolean;
        icon: ReactNode;
      }
    | {
        onClick: () => void;
        text: string;
        icon: ReactNode;
      };
  media?: ReactNode;
  title: {
    text: string;
    as: TextProps['as'];
  };
  text: string;
  badgeText: string;
}

const Wrapper = styled.aside`
  position: relative;
  background: ${props => props.theme.colorBackgroundGuideBannerDefault};
  color: ${props => props.theme.colorTextGuideBannerBodyDefault};
  padding: ${SPACING_3};
  border-radius: ${props => props.theme.radiusGuideBannerMaxi};
  display: flex;
  flex-direction: column;
  gap: ${SPACING_1};
  container-type: inline-size;
`;

const TopRow = styled.div`
  display: flex;
  gap: ${SPACING_1};
  justify-content: space-between;
  align-items: center;
`;
const Body = styled.div`
  display: flex;
  gap: ${SPACING_3};

  ${media.lessThan('phablet')} {
    flex-direction: column-reverse;
  }
`;

const Texts = styled.div<{ $hasAction: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${SPACING_0_5};
  padding-bottom: calc(
    ${SPACING_1} + ${props => (props.$hasAction ? props.theme.sizingButtonHeight : 0)}
  );

  > *:first-child {
    color: ${props => props.theme.colorTextGuideBannerHeadingDefault};
  }
  > *:last-child {
    color: ${props => props.theme.colorTextGuideBannerBodyDefault};

    p {
      margin: 0;
      font-size: 0.875rem;
    }
  }
`;

const ActionButtonWrapper = styled.div`
  position: absolute;
  bottom: ${SPACING_3};
  left: ${SPACING_3};
  max-width: calc(100% - (${SPACING_3} * 2));
`;

const MediaWrapper = styled.div`
  align-self: center;
  width: 18rem;
  margin: 0 auto;
  flex-shrink: 0;
  > * {
    width: 100%;
  }
`;

export const GuideBanner = (props: GuideBannerProps) => {
  const [closed, setClosed] = useState(false);
  const { t } = useTranslation();

  if (closed) return null;

  return (
    <DefaultTheme>
      <Wrapper>
        <TopRow>
          <Badge variant="informational" text={props.badgeText} onDark />
          <Button
            configuration="text"
            inline
            type="button"
            onClick={() => {
              setClosed(true);
              props.onClose?.();
            }}
            icon={<Icons.Close />}
            aria-label={t('common_close')}
          />
        </TopRow>
        <Body className={queries.body}>
          <Texts $hasAction={!!props.action}>
            <Text variant="headlineSmall" as={props.title.as}>
              {props.title.text}
            </Text>
            <Text variant="body" as="p">
              {props.text}
            </Text>
          </Texts>
          {props.media && (
            <MediaWrapper className={queries['media-wrapper']}>{props.media}</MediaWrapper>
          )}
        </Body>
        {props.action && (
          <ActionButtonWrapper>
            <Button
              configuration="text"
              {...('href' in props.action
                ? {
                    href: props.action.href,
                    onClick: props.action.onClick,
                    newTab: props.action.newTab,
                  }
                : { type: 'button', onClick: props.action.onClick })}
              leadingIcon={props.action.icon}
            >
              {props.action.text}
            </Button>
          </ActionButtonWrapper>
        )}
      </Wrapper>
    </DefaultTheme>
  );
};
