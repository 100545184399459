import { type ReactNode, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import * as possibleColors from '../../theme/colors';
import * as possibleSpacings from '../../theme/spacings';
import media from '../utils/media';

const baseTextStyles = {
  displayLarge: css`
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 4.25rem;
  `,
  displayMedium: css`
    font-size: 3rem;
    font-weight: 700;
    line-height: 3.625rem;
  `,
  displaySmall: css`
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3.0625rem;
  `,
  headlineLarge: css`
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
  `,
  headlineMedium: css`
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.875rem;
  `,
  headlineSmall: css`
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.75rem;
  `,
  subheading: css`
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem;
  `,
  labelMedium: css`
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  `,
  labelSmall: css`
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.3125rem;
  `,
  body: css`
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  `,
  bodySmall: css`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
  `,
  bodyLarge: css`
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.6875rem;
  `,
};

/*
Styles specifically for Reports.
These might at some point be merged with the above styles.
Keeping them here will allow us to use them separately,
but still have a clear overview of all text styles.
*/
const reportTextStyles = {
  reportDisplayLarge: css`
    font-size: 6.375rem;
    font-weight: 700;
    line-height: 6.875rem;
    letter-spacing: -0.3125rem;
    word-break: break-word;
  `,
  reportDisplayMedium: css`
    font-size: 4.75rem; /* 76px / 16px */
    font-weight: 400;
    line-height: 4.25rem; /* 68px / 16px */
    letter-spacing: 0;
    leading-trim: both;
    text-edge: cap;
  `,
  reportDisplaySmall: css`
    font-size: 3.125rem; /* 50px / 16px */
    font-weight: 700;
    line-height: 3.0625rem; /* 49px / 16px */
    letter-spacing: -0.0625rem; /* -1px / 16px */
  `,
  reportHeadlineLarge: css`
    font-size: 2rem; /* 32px / 16px */
    font-weight: 700;
    line-height: 2.5rem; /* 40px / 16px */
    letter-spacing: 0;
  `,
  reportHeadlineMedium: css`
    font-size: 1.125rem; /* 18px / 16px */
    font-weight: 500;
    line-height: 1.3125rem; /* 21px / 16px */
    letter-spacing: 0;
  `,
  reportHeadlineSmall: css`
    font-size: 1rem; /* 16px / 16px */
    font-weight: 600;
    line-height: 1.125rem; /* 18px / 16px */
    letter-spacing: 0;
  `,
  reportLabelMedium: css`
    font-size: 0.8125rem; /* 13px / 16px */
    font-weight: 600;
    line-height: 1.125rem; /* 18px / 16px */
    letter-spacing: 0;
  `,
  reportLabelSmall: css`
    font-size: 0.75rem; /* 12px / 16px */
    font-weight: 500;
    line-height: 1.125rem; /* 18px / 16px */
    letter-spacing: 0;
  `,
};

export const textStyles = {
  ...baseTextStyles,
  ...reportTextStyles,
} as const;

type Variant = keyof typeof textStyles;
type TextAlign = 'left' | 'center' | 'right';

const StyledText = styled.div<{
  $variant: Variant;
  $mobileVariant?: Variant;
  $marginTop?: string;
  $marginBottom?: string;
  $color?: string;
  $textAlign?: TextAlign;
}>`
  ${props => textStyles[props.$variant]};
  ${props =>
    props.$mobileVariant &&
    css`
      ${media.lessThan('phablet')} {
        ${textStyles[props.$mobileVariant]}
      }
    `}
  margin: 0;
  margin-top: ${props => props.$marginTop};
  margin-bottom: ${props => props.$marginBottom};
  padding: 0;
  color: ${props => props.$color};
  font-style: normal;
  && {
    /* Text-align prop should always be first */
    text-align: ${props => (props.$textAlign ? props.$textAlign : undefined)};
  }
`;

export interface TextProps {
  as: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'div' | 'span' | 'li' | 'label';
  variant: Variant;
  mobileVariant?: Variant;
  children: ReactNode;
  marginTop?: keyof typeof possibleSpacings;
  marginBottom?: keyof typeof possibleSpacings;
  color?: keyof typeof possibleColors;
  textAlign?: TextAlign;
  className?: string;
  id?: string;
  htmlFor?: string;
}

export const Text = forwardRef<HTMLElement, TextProps>(
  (
    {
      id,
      as,
      variant,
      mobileVariant,
      children,
      marginTop,
      marginBottom,
      color,
      textAlign,
      className,
      htmlFor,
    },
    ref,
  ) => {
    return (
      <StyledText
        ref={ref}
        className={className}
        id={id}
        as={as}
        $variant={variant}
        $mobileVariant={mobileVariant}
        $marginTop={marginTop ? possibleSpacings[marginTop] : undefined}
        $marginBottom={marginBottom ? possibleSpacings[marginBottom] : undefined}
        $color={color ? possibleColors[color] : undefined}
        $textAlign={textAlign}
        htmlFor={htmlFor}
      >
        {children}
      </StyledText>
    );
  },
);
