import styled from 'styled-components';
import {
  GREY_400,
  RADIUS_2XL,
  RADIUS_LG,
  RADIUS_MD,
  RADIUS_SM,
  RADIUS_XL,
  SIZING_2,
} from '../../../theme';

type Radius = '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'round' | 'unset';

const RADIUSES = {
  '2xl': RADIUS_2XL,
  xl: RADIUS_XL,
  lg: RADIUS_LG,
  md: RADIUS_MD,
  sm: RADIUS_SM,
  round: '50%',
  unset: 'unset',
};

const SkeletonBarBase = styled.div<{
  $height?: string;
  $width: string;
  $aspectRatio?: string;
  $marginTop?: number;
  $marginBottom?: number;
  $borderRadius: Radius;
}>`
  position: relative;
  height: ${({ $aspectRatio, $height }) => ($aspectRatio ? 'unset' : $height ? $height : SIZING_2)};
  width: ${({ $width }) => $width};
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio};
  background: ${GREY_400};
  border-radius: ${({ $borderRadius }) => RADIUSES[$borderRadius]};
  margin-top: ${({ $marginTop }) => $marginTop ?? 0}rem;
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? 0}rem;
  max-width: 90vw;
`;

type SkeletonBarProps = {
  height?: string;
  width: string;
  aspectRatio?: string;
  marginTop?: number;
  marginBottom?: number;
  borderRadius?: Radius;
  children?: React.ReactNode;
};

const SkeletonBar = ({
  height,
  width,
  aspectRatio,
  marginTop,
  marginBottom,
  borderRadius = 'md',
  children,
}: SkeletonBarProps) => {
  return (
    <SkeletonBarBase
      $height={height}
      $width={width}
      $aspectRatio={aspectRatio}
      $marginTop={marginTop}
      $marginBottom={marginBottom}
      $borderRadius={borderRadius}
    >
      {children}
    </SkeletonBarBase>
  );
};

export default SkeletonBar;
