import styled, { css } from 'styled-components';
import type { ContentType } from './types';

const HeaderCell = styled.th<{
  $textAlign?: string;
  $withWrap?: boolean;
  $contentType?: ContentType;
}>`
  padding: 1rem 1.5rem;
  text-align: ${({ $textAlign }) => $textAlign || 'initial'};
  white-space: ${({ $withWrap }) => ($withWrap ? 'normal' : 'nowrap')};
  word-break: ${({ $withWrap }) => ($withWrap ? 'break-all' : 'normal')};
  overflow: hidden;

  & * {
    margin: 0;
  }

  ${({ $contentType }) =>
    $contentType === 'numeric' &&
    css`
      text-align: right;
    `}
`;

export default HeaderCell;
