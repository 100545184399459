import { useClickOutside } from '@mnd-frontend/hooks';
import {
  type MouseEvent,
  type ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import styled, { css, keyframes } from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import {
  FOCUS_SHADOW,
  GREY_600,
  GREY_800,
  NEUTRAL_WHITE,
  PURPLE_400,
  PURPLE_700,
  PURPLE_800,
  SHADOW_1,
} from '../../theme';
import { Button } from '../Button';
import Icons from '../Icons';
import { ScreenWrapper } from '../Screen/styles';
import { H4, Meta, P, ScreenReaderOnly } from '../Typo';
import { getPortalContainer } from '../utils/getPortalContainer';

const iconColor = PURPLE_700;
const iconColorHover = PURPLE_800;
const iconColorDisabled = PURPLE_400;

const iconSize = '1.25rem';
const DEFAULT_STYLE = { top: '0px', left: '0px' };

const fadein = keyframes`
    0% { opacity: 0; }
    100% {opacity: 1; }
`;

const TooltipToggler = styled.button`
  width: ${iconSize};
  min-width: ${iconSize};
  height: ${iconSize};
  padding: 0;
  line-height: 1;
  font-size: 1rem;
  position: relative;
  text-align: center;

  i {
    vertical-align: middle;
    line-height: 0;
    font-size: 0;
    width: calc(${iconSize} + 2px);
    height: calc(${iconSize} + 2px);
    position: absolute;
    top: -1px;
    left: -2px;
  }

  i svg {
    width: ${iconSize};
    height: ${iconSize};
    pointer-events: none;
    transition: transform 0.1s ease-out;
    display: inline-block;
  }

  svg {
    color: ${iconColor};
  }

  &:disabled > i svg {
    color: ${iconColorDisabled};
  }

  background: transparent;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 50%;

  &:hover {
    background: transparent;
    transition: all 0.3s ease;
    & > i svg {
      color: ${iconColorHover};
    }
  }

  &:focus {
    background: transparent;
    z-index: 1;
    outline: none;
    border-color: ${PURPLE_700};
    box-shadow: ${FOCUS_SHADOW};
  }
`;

const TooltipContent = styled.span`
  display: inline-block;
  margin: 0 0.25rem;
  vertical-align: top;
  line-height: 0;
  height: ${iconSize};
`;

type Props = {
  $placement?: 'left' | 'right' | 'bottom' | 'top' | undefined;
};

const TooltipHeader = styled.div<Props>`
  > h4 {
    margin: 0;
    padding: 0;
  }
  > button {
    margin-right: -0.75rem;
  }
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const TooltipDescription = styled.div<Props>`
  border-radius: 0.25rem;
  background-color: ${NEUTRAL_WHITE};
  box-shadow: ${SHADOW_1};
  border: 1px solid ${GREY_600};

  color: ${GREY_800};
  max-width: 22rem;
  animation: ${fadein} 200ms cubic-bezier(0.25, 0.55, 0.3, 1);
  padding: 0.25rem 1rem 1rem;
  position: absolute;
  z-index: 9000;
  ${P} {
    color: ${GREY_800};
  }

  ${({ $placement }) => {
    switch ($placement) {
      case 'left':
        return css`
          margin-left: -0.75rem;
        `;
      case 'right':
        return css`
          margin-left: 1rem;
        `;
      case 'bottom':
        return css`
          margin-top: 1rem;
        `;
      default:
        return css`
          margin-top: -1rem;
        `;
    }
  }}

  &:after,
  &:before {
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    ${({ $placement }) => {
      switch ($placement) {
        case 'left':
          return css`
            left: 100%;
            top: 50%;
          `;
        case 'right':
          return css`
            right: 100%;
            top: 50%;
          `;
        case 'bottom':
          return css`
            bottom: 100%;
            left: 50%;
          `;
        default:
          return css`
            top: 100%;
            left: 50%;
          `;
      }
    }}
  }

  &:after {
    background-color: ${NEUTRAL_WHITE};
    transform-origin: center;

    width: 0.5rem;
    height: 2rem;
    transform: translate(-100%, -50%);

    ${({ $placement }) => {
      switch ($placement) {
        case 'left':
          return css``;
        case 'right':
          return css`
            transform: translate(100%, -50%);
          `;
        case 'bottom':
          return css`
            width: 2rem;
            height: 0.5rem;
            transform: translate(-50%, 100%);
          `;
        default:
          return css`
            width: 2rem;
            height: 0.5rem;
            transform: translate(-50%, -100%);
          `;
      }
    }}
  }

  &:before {
    width: 0.5rem;
    height: 0.5rem;
    background-color: ${NEUTRAL_WHITE};
    box-shadow: ${SHADOW_1};
    border: 1px solid ${GREY_600};

    transform-origin: center;
    transform: translate(-50%, -50%) rotate(45deg);

    ${({ $placement }) => {
      switch ($placement) {
        case 'left':
          return css``;
        case 'right':
          return css`
            transform: translate(50%, -50%) rotate(45deg);
          `;
        case 'bottom':
          return css`
            transform: translate(-50%, 50%) rotate(45deg);
          `;
        default:
          return css`
            transform: translate(-50%, -50%) rotate(45deg);
          `;
      }
    }}
  }
`;

const getPlacementStyle = (
  contentElement: HTMLElement,
  descriptionElement: HTMLElement,
  placement: 'top' | 'right' | 'bottom' | 'left',
  inPositionFixed?: boolean,
) => {
  const content = contentElement.getBoundingClientRect();
  const description = descriptionElement.getBoundingClientRect();
  let parent = null;
  if (!inPositionFixed) {
    const modals = document.querySelectorAll('[data-backdrop]') as NodeListOf<HTMLDivElement>;
    parent = modals[modals.length - 1];
    parent ||= document.querySelector(ScreenWrapper.toString());
  }
  const xpos = parent?.scrollLeft ?? window.pageXOffset;
  const ypos = parent?.scrollTop ?? window.pageYOffset;
  const minX = 16;
  let x: number;
  let y: number;
  let tx: number;
  let ty: number;

  if (placement === 'left') {
    x = Math.round(xpos + content.left);
  } else if (placement === 'right') {
    x = Math.round(xpos + content.right);
  } else {
    x = Math.round(xpos + content.left + content.width * 0.5);
  }

  if (placement === 'left' || placement === 'right') {
    y = Math.round(ypos + content.top + content.height * 0.5);
  } else if (placement === 'bottom') {
    y = Math.round(ypos + content.bottom);
  } else {
    y = Math.round(ypos + content.top);
  }

  if (placement === 'left') {
    tx = Math.max(minX, Math.round(x - description.width));
  } else if (placement === 'right') {
    tx = x;
  } else {
    tx = Math.max(minX, Math.round(x - description.width * 0.5));
  }

  if (placement === 'left' || placement === 'right') {
    ty = Math.round(y - description.height * 0.5);
  } else if (placement === 'bottom') {
    ty = y;
  } else {
    ty = Math.round(y - description.height);
  }
  return { top: `${ty}px`, left: `${tx}px` };
};

const Portal = forwardRef<HTMLDivElement, { children: ReactNode; inPositionFixed?: boolean }>(
  ({ children, inPositionFixed }, ref) => {
    const el = getPortalContainer('tooltiphelp', inPositionFixed ? [] : undefined);
    if (!el) return null;

    return ReactDOM.createPortal(<div ref={ref}>{children}</div>, el);
  },
);

const TooltipHelp = ({
  header,
  content,
  id,
  placement = 'right',
  inPositionFixed,
  dataTestId,
}: {
  header?: string;
  content: ReactNode;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  id?: string;
  inPositionFixed?: boolean;
  dataTestId?: string;
}): JSX.Element => {
  const descriptionElement = useRef<HTMLDivElement>(null);
  const contentElement = useRef<HTMLSpanElement>(null);
  const portalElement = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState(DEFAULT_STYLE);
  const [active, setActive] = useState(false);

  const { t } = useTranslation();

  const dismiss = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setActive(false);
    },
    [setActive],
  );
  const toggle = useCallback(() => setActive(!active), [active, setActive]);

  useEffect(() => {
    const content = contentElement.current;
    const description = descriptionElement.current;

    if (content && description) {
      const handleResize = () =>
        setStyle(getPlacementStyle(content, description, placement, inPositionFixed));
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }

    return;
  }, [descriptionElement, contentElement, placement, inPositionFixed]);

  useEffect(() => {
    if (!active) {
      setStyle(DEFAULT_STYLE);
      return;
    }
    const content = contentElement.current;
    const description = descriptionElement.current;
    if (content && description) {
      setStyle(getPlacementStyle(content, description, placement, inPositionFixed));
    }
  }, [active, descriptionElement, contentElement, placement, inPositionFixed]);

  useClickOutside(
    portalElement,
    () => {
      setActive(false);
    },
    active,
  );

  return (
    <>
      <TooltipContent ref={contentElement}>
        <TooltipToggler onClick={toggle} type="button" data-testid={dataTestId}>
          <Icons.CircleQuestion />
          <ScreenReaderOnly>?</ScreenReaderOnly>
        </TooltipToggler>
      </TooltipContent>
      {active && (
        <Portal inPositionFixed={inPositionFixed} ref={portalElement}>
          <TooltipDescription
            id={id}
            ref={descriptionElement}
            role="tooltip"
            $placement={placement}
            style={style}
          >
            <TooltipHeader>
              <H4>{header}</H4>
              <Button
                onClick={dismiss}
                type="button"
                configuration="text"
                icon={<Icons.Close />}
                aria-label={t('component_dismiss-label')}
              />
            </TooltipHeader>
            <Meta>{content}</Meta>
          </TooltipDescription>
        </Portal>
      )}
    </>
  );
};

export default TooltipHelp;
