import { Box, type BoxProps } from './Box';
import { Midi, type MidiProps } from './Midi';
import { Mini, type MiniProps } from './Mini';

type Props = BoxProps | MiniProps | MidiProps;

export const AssistanceBanner = (props: Props) => {
  switch (props.variant) {
    case 'box':
      return <Box {...props} />;
    case 'mini':
      return <Mini {...props} />;
    case 'midi':
      return <Midi {...props} />;
  }
};
