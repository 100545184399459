import {
  PURPLE_400,
  PURPLE_700,
  PURPLE_800,
  PURPLE_900,
  RED_500,
  RED_700,
  RED_1000,
  SUCCESS_200,
  SUCCESS_700,
  SUCCESS_1000,
  WARNING_100,
  WARNING_400,
  WARNING_700,
  WARNING_900,
} from './colors';

// ----------------NOT IN FIGMA------------------//

export const OCEAN_20 = '#B5F5FC';
export const OCEAN_100 = '#5ae6f6';
export const OCEAN_180 = '#00A8BC';

// ----------------GRAPH COLORS------------------//

export const GRAPH_COLORS = [PURPLE_800, RED_700, OCEAN_100, WARNING_400];

export const GRAPH_COLORS_RASPBERRY = [RED_500, RED_700, RED_1000];
export const GRAPH_COLORS_BLUEBERRY = [PURPLE_400, PURPLE_700, PURPLE_900];
export const GRAPH_COLORS_OCEAN = [OCEAN_20, OCEAN_100, OCEAN_180];
export const GRAPH_COLORS_MANDARIN = [WARNING_100, WARNING_700, WARNING_900];
export const GRAPH_COLORS_APPLE = [SUCCESS_200, SUCCESS_700, SUCCESS_1000];

export const GRAPH_COLORS_ALL = [
  GRAPH_COLORS_BLUEBERRY,
  GRAPH_COLORS_RASPBERRY,
  GRAPH_COLORS_OCEAN,
  GRAPH_COLORS_MANDARIN,
  GRAPH_COLORS_APPLE,
];
