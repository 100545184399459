import styled from 'styled-components';
import { GREY_400, GREY_800, GREY_900, PURPLE_700 } from '../../theme';
import { media } from '../utils/media';
import type { TableBodyProps } from './TableBody';
import type { TableHeaderProps } from './TableHeader';

const Body = styled.tbody`
  display: none;
  ${media.lessThan('tablet')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 1.5rem;
    position: relative;
    padding-left: 1.5rem;
    padding-right: 0.5rem;
    padding-top: 1.5rem;

    &:not(:last-child)::after {
      content: '';
      background: ${GREY_400};
      bottom: 0;
      height: 1px;
      left: -1.625rem;
      position: absolute;
      right: -1.625rem;
    }
  }
`;

const Cell = styled.td`
  color: ${GREY_800};
  display: block;
  font-size: 0.8125rem;
  line-height: 1.75rem;
  text-align: right;
  padding-right: 1rem;
`;

const HeadingCell = styled(Cell)`
  color: ${GREY_900};
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
`;

const Row = styled.tr`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: span 2;
`;

const ClickableCell = styled.td`
  font-weight: 700;
  padding: 0.375rem 0.375rem 0.375rem 0;
  line-height: 1.5rem;
  width: 100%;
  a {
    display: table;
    color: ${PURPLE_700};
    cursor: pointer;
  }
  *:not(:first-child) {
    color: ${GREY_800};
    font-size: 0.8125rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
`;

const TableMobileBody = ({
  rows,
  headings,
  hideHeaderOnMobile = false,
  preserveLastColumnOnMobile = false,
}: TableHeaderProps &
  TableBodyProps & { hideHeaderOnMobile?: boolean; preserveLastColumnOnMobile?: boolean }) => {
  return (
    <>
      {rows?.map((row, index) => (
        <Body key={index}>
          <Row>
            <ClickableCell>{row[0]}</ClickableCell>
            {hideHeaderOnMobile && preserveLastColumnOnMobile && <td>{row[row.length - 1]}</td>}
          </Row>
          {!hideHeaderOnMobile && (
            <>
              <tr>
                {headings?.slice(1).map((heading, headingIndex) => (
                  <HeadingCell key={headingIndex}>{heading.text}</HeadingCell>
                ))}
              </tr>
              <tr>
                {row.slice(1).map((data, dataIndex) => (
                  <Cell key={dataIndex}>{data}</Cell>
                ))}
              </tr>
            </>
          )}
        </Body>
      ))}
    </>
  );
};

export default TableMobileBody;
