import type React from 'react';
import SvgSymbolWrapper from './SvgSymbolWrapper';
import { ReactComponent as AirplaneWithBackground } from './svgs/airplane-with-background.svg';
import { ReactComponent as Airplane } from './svgs/airplane.svg';
// not in figma
import { ReactComponent as AlertSign } from './svgs/alert-sign.svg';
import { ReactComponent as Link } from './svgs/anchor-link.svg';
import { ReactComponent as Android } from './svgs/android.svg';
import { ReactComponent as AppearPassword } from './svgs/appear-password.svg';
import { ReactComponent as ArticlesToday } from './svgs/articles-today.svg';
// in figma;
import { ReactComponent as Articles } from './svgs/articles.svg';
import { ReactComponent as AvatarContactWithBackground } from './svgs/avatar-contact.svg';
import { ReactComponent as Average } from './svgs/average.svg';
import { ReactComponent as BrokenLink } from './svgs/broken-link.svg';
import { ReactComponent as Calendar } from './svgs/calendar.svg';
import { ReactComponent as CheckDone } from './svgs/check-done.svg';
import { ReactComponent as Upload } from './svgs/cloud-upload.svg';
import { ReactComponent as DocumentPage } from './svgs/document-page.svg';
import { ReactComponent as Download } from './svgs/file-download.svg';
import { ReactComponent as File } from './svgs/file.svg';
import { ReactComponent as ForwardTime } from './svgs/forward-time.svg';
import { ReactComponent as Group } from './svgs/group.svg';
import { ReactComponent as HeaderImages } from './svgs/headerimages.svg';
import { ReactComponent as Headphones } from './svgs/headphones.svg';
import { ReactComponent as HidePassword } from './svgs/hide-password.svg';
import { ReactComponent as IPhone } from './svgs/iphone.svg';
import { ReactComponent as Laptop } from './svgs/laptop.svg';
import { ReactComponent as NewUser } from './svgs/new-user.svg';
import { ReactComponent as Newspaper } from './svgs/newspaper.svg';
import { ReactComponent as Newsroom } from './svgs/newsroom.svg';
import { ReactComponent as NoPublications } from './svgs/no-publications.svg';
import { ReactComponent as NoResults } from './svgs/no-result.svg';
import { ReactComponent as PerformanceBoard } from './svgs/performance-board.svg';
import { ReactComponent as Preview } from './svgs/preview.svg';
import { ReactComponent as RelatedContent } from './svgs/relatedcontent.svg';
import { ReactComponent as RocketLaunch } from './svgs/rocket-launch.svg';
import { ReactComponent as Rocket } from './svgs/rocket.svg';
import { ReactComponent as SendingMessage } from './svgs/sending-message.svg';
import { ReactComponent as SocialChannels } from './svgs/socialchannels.svg';
import { ReactComponent as ThumbsUp } from './svgs/thumbs-up.svg';
import { ReactComponent as VideoCam } from './svgs/video-cam.svg';
import { ReactComponent as Watch } from './svgs/watch.svg';

type Props = {
  size?: 'small' | 'medium' | 'large';
  variant?: string;
} & React.HTMLAttributes<HTMLElement>;

export type SymbolsType = React.FunctionComponent<Props>;

const wrapSymbol = (Component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>) =>
  function Symbol({ size, ...props }: Props) {
    return (
      <SvgSymbolWrapper $size={size} {...props}>
        <Component />
      </SvgSymbolWrapper>
    );
  };

const Symbols = {
  // in figma
  Airplane: wrapSymbol(Airplane),
  AirplaneWithBackground: wrapSymbol(AirplaneWithBackground),
  Android: wrapSymbol(Android),
  AppearPassword: wrapSymbol(AppearPassword),
  Articles: wrapSymbol(Articles),
  ArticlesToday: wrapSymbol(ArticlesToday),
  AvatarContactWithBackground: wrapSymbol(AvatarContactWithBackground),
  Average: wrapSymbol(Average),
  Calendar: wrapSymbol(Calendar),
  Download: wrapSymbol(Download),
  ForwardTime: wrapSymbol(ForwardTime),
  Group: wrapSymbol(Group),
  HeaderImages: wrapSymbol(HeaderImages),
  IPhone: wrapSymbol(IPhone),
  Laptop: wrapSymbol(Laptop),
  Link: wrapSymbol(Link),
  Newspaper: wrapSymbol(Newspaper),
  Newsroom: wrapSymbol(Newsroom),
  NewUser: wrapSymbol(NewUser),
  NoResults: wrapSymbol(NoResults),
  PerformanceBoard: wrapSymbol(PerformanceBoard),
  Preview: wrapSymbol(Preview),
  RelatedContent: wrapSymbol(RelatedContent),
  SendingMessage: wrapSymbol(SendingMessage),
  SocialChannels: wrapSymbol(SocialChannels),
  Watch: wrapSymbol(Watch),

  // not in figma
  AlertSign: wrapSymbol(AlertSign),
  BrokenLink: wrapSymbol(BrokenLink),
  CheckDone: wrapSymbol(CheckDone),
  DocumentPage: wrapSymbol(DocumentPage),
  File: wrapSymbol(File),
  Headphones: wrapSymbol(Headphones),
  HidePassword: wrapSymbol(HidePassword),
  NoPublications: wrapSymbol(NoPublications),
  Rocket: wrapSymbol(Rocket),
  RocketLaunch: wrapSymbol(RocketLaunch),
  UploadNoWrapper: Upload,
  ThumbsUp: wrapSymbol(ThumbsUp),
  VideoCam: wrapSymbol(VideoCam),
};

export default Symbols as { [key in keyof typeof Symbols]: SymbolsType };
