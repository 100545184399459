import { useMediaQuery } from '@mnd-frontend/hooks';
import { type ReactNode, createContext, memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  GREY_100,
  GREY_300,
  GREY_800,
  GREY_900,
  RADIUS_MD,
  SPACING_0_5,
  SPACING_1,
  SPACING_2,
  SPACING_3,
  SPACING_5,
  SPACING_9,
} from '../../theme';
import Avatar from '../Avatar';
import { Badge } from '../Badge';
import { Checkbox, type CheckboxState } from '../Checkbox';
import Icons from '../Icons';
import { panelLoadingCss } from '../Panel';
import { SkeletonBar } from '../Skeleton';
import { Text, textStyles } from '../Text';
import { media } from '../utils/media';

const Container = styled.div`
  background-color: ${GREY_300};
  border-radius: ${RADIUS_MD};
  overflow: hidden;
  width: 100%;
  position: relative;
`;

const GRID_TEMPLATE_WITH_CHECKBOX = ($columns: number) => `auto 3fr repeat(${$columns}, 1fr)`;
const GRID_TEMPLATE_WITH_CHECKBOX_MOBILE = '0fr auto 0fr';
const GRID_TEMPLATE_DEFAULT = ($columns: number) => `3fr repeat(${$columns}, 1fr)`;
const GRID_TEMPLATE_DEFAULT_MOBILE = 'auto 0fr';

const Header = styled.div<{ $columns: number; $isCheckboxVisible?: boolean }>`
  padding: ${SPACING_2} ${SPACING_5};
  display: grid;
  grid-template-columns: ${({ $columns, $isCheckboxVisible }) =>
    $isCheckboxVisible ? GRID_TEMPLATE_WITH_CHECKBOX($columns) : GRID_TEMPLATE_DEFAULT($columns)};
  ${media.lessThan('desktop')} {
    grid-template-columns: ${({ $isCheckboxVisible }) =>
      $isCheckboxVisible ? GRID_TEMPLATE_WITH_CHECKBOX_MOBILE : GRID_TEMPLATE_DEFAULT_MOBILE};
  }
`;

const ColumnHeader = styled.div<{
  $sort?: boolean;
  $alignment?: 'left' | 'right' | 'center';
}>`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3125rem;
  color: ${GREY_900};
  display: flex;
  align-items: center;
  gap: ${SPACING_0_5};
  cursor: ${({ $sort }) => ($sort ? 'pointer' : 'default')};
  justify-self: ${({ $alignment }) => $alignment};
`;

const SelectColumnHeader = styled.div<{ $alignment?: 'left' | 'right' | 'center' }>`
  display: flex;
  align-items: center;
  margin-right: ${SPACING_3};
  justify-self: ${({ $alignment }) => $alignment};
`;

const Cell = styled.div<{ $alignment?: 'left' | 'right' | 'center' }>`
  justify-self: ${({ $alignment }) => $alignment};
`;

const SelectCell = styled(Cell)`
  margin-right: ${SPACING_3};
`;

const MainContentCell = styled(Cell)`
  justify-self: start;
  width: 100%;
`;

const Row = styled.div<{ $columns: number; $isCheckboxVisible?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $columns, $isCheckboxVisible }) =>
    $isCheckboxVisible ? GRID_TEMPLATE_WITH_CHECKBOX($columns) : GRID_TEMPLATE_DEFAULT($columns)};
  align-items: center;
  padding: ${SPACING_2} ${SPACING_5};
  border-top: 1px solid ${({ theme }) => theme.colorBorderDividerDefault};
  &:hover {
    background: ${GREY_100};
  }
  ${media.lessThan('desktop')} {
    grid-template-columns: ${({ $isCheckboxVisible }) =>
      $isCheckboxVisible ? GRID_TEMPLATE_WITH_CHECKBOX_MOBILE : GRID_TEMPLATE_DEFAULT_MOBILE};
  }
`;

const RowContainer = styled.div`
  background: #ffffff;
`;

const ContactWrapper = styled.div`
  display: grid;
  align-items: center;
  gap: ${SPACING_3};
  grid-template-columns: auto 1fr;
  cursor: pointer;
  ${media.lessThan('desktop')} {
    margin-right: ${SPACING_1};
  }
`;

const ContactName = styled.div`
  display: grid;
  gap: ${SPACING_0_5};
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${SPACING_1};
`;

const EmptyState = styled.div`
  display: grid;
  justify-content: center;
  padding: ${SPACING_9} ${SPACING_5};
  p {
    max-width: 30rem;
    text-align: center;
  }
`;

const TruncateDescription = styled.p`
  ${textStyles.bodySmall}
  color: ${GREY_800};
  margin: 0;
  max-width: 22.75rem;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LoadingWrapper = styled.div`
  ${panelLoadingCss}
`;

const LoadingContainer = memo(({ columnsCount }: { columnsCount: number }) => (
  <LoadingWrapper>
    <Header $columns={columnsCount}>
      {Array.from({ length: columnsCount }).map((_, index) => (
        <ColumnHeader key={index} style={{ height: '1.3125rem' }}>
          <SkeletonBar width="5rem" />
        </ColumnHeader>
      ))}
    </Header>
    <RowContainer>
      {Array.from({ length: 25 }).map((_, index) => (
        <Row key={index} $columns={columnsCount}>
          {Array.from({ length: columnsCount + 1 }).map((_, index) =>
            index === 0 ? (
              <MainContentCell key={index}>
                <ContactWrapper>
                  <Avatar type="contact" />
                  <ContactName>
                    <SkeletonBar width="10rem" />
                    <SkeletonBar width="6rem" />
                  </ContactName>
                </ContactWrapper>
              </MainContentCell>
            ) : (
              <Cell key={index}>
                <SkeletonBar width="5rem" />
              </Cell>
            ),
          )}
        </Row>
      ))}
    </RowContainer>
  </LoadingWrapper>
));

const ContactCell = memo(
  ({ name, description, image, onContactClick, status }: ContactCellType) => {
    const { t } = useTranslation();

    return (
      <MainContentCell>
        <ContactWrapper onClick={onContactClick}>
          <Avatar type="contact" image={image ?? undefined} />
          <ContactName>
            <NameWrapper>
              <Text as="p" variant="labelMedium">
                {name}
              </Text>
              {status === 'imported' && (
                <Badge variant="informational" text={t('common_imported')} />
              )}
              {status === 'new' && <Badge variant="commercial" text={t('common_new')} />}
            </NameWrapper>
            {description && <TruncateDescription>{description}</TruncateDescription>}
          </ContactName>
        </ContactWrapper>
      </MainContentCell>
    );
  },
);

interface ListHeaderProps {
  headings: Heading[];
  columnsCount: number;
  isCheckboxVisible?: boolean;
  isCheckboxPropProvided?: boolean;
}

const ListHeader = memo(
  ({ headings, columnsCount, isCheckboxVisible, isCheckboxPropProvided }: ListHeaderProps) => {
    const filteredHeadings = useMemo(
      () =>
        isCheckboxVisible ? headings : headings.filter(heading => !('checkboxState' in heading)),
      [headings, isCheckboxVisible],
    );

    const { alignments } = useContext(ColumnAlignmentContext);

    return (
      <Header $columns={columnsCount} $isCheckboxVisible={isCheckboxVisible}>
        {filteredHeadings.map((heading, index) => {
          const alignmentConfigWithCheckbox = isCheckboxVisible ? index : index + 1;
          const columnHeaderAlignment =
            alignments[isCheckboxPropProvided ? alignmentConfigWithCheckbox : index];

          switch (true) {
            case 'sort' in heading:
              return (
                <ColumnHeader
                  key={index}
                  onClick={e => {
                    e.preventDefault();
                    heading.onSortChange?.(heading.sort === 'asc' ? 'desc' : 'asc');
                  }}
                  $sort={!!heading.onSortChange}
                  $alignment={columnHeaderAlignment}
                >
                  {heading.text}{' '}
                  {heading.sort === 'asc' ? (
                    <Icons.ArrowDown $size="xxs" />
                  ) : heading.sort === 'desc' ? (
                    <Icons.ArrowUp $size="xxs" />
                  ) : null}
                </ColumnHeader>
              );

            case 'checkboxState' in heading && isCheckboxVisible:
              return (
                <SelectColumnHeader key={index} $alignment={columnHeaderAlignment}>
                  <Checkbox
                    state={heading.checkboxState}
                    onChange={heading.onSelectAllChange}
                    data-testid={heading?.dataTestId}
                  />
                </SelectColumnHeader>
              );

            case 'text' in heading:
              return (
                <ColumnHeader key={index} $alignment={columnHeaderAlignment}>
                  {heading.text}
                </ColumnHeader>
              );

            default:
              return null;
          }
        })}
      </Header>
    );
  },
);

interface ListRowProps {
  row: Row;
  columnsCount: number;
  isCheckboxVisible?: boolean;
  isCheckboxPropProvided?: boolean;
}

const isContact = (item: RowItem): item is ContactCellType =>
  (item as ContactCellType)?.type === 'contact';
const isCheckbox = (item: RowItem): item is CheckboxCellType =>
  (item as CheckboxCellType)?.type === 'checkbox';

const ListRow = memo(
  ({ row, columnsCount, isCheckboxVisible, isCheckboxPropProvided }: ListRowProps) => {
    const filteredRow = isCheckboxVisible ? row : row.filter(item => !isCheckbox(item));
    const { alignments } = useContext(ColumnAlignmentContext);

    return (
      <Row $columns={columnsCount} $isCheckboxVisible={isCheckboxVisible}>
        {filteredRow.map((item, index) => {
          const alignmentConfigWithCheckbox = isCheckboxVisible ? index : index + 1;

          const cellAlignment =
            alignments[isCheckboxPropProvided ? alignmentConfigWithCheckbox : index];

          switch (true) {
            case isContact(item):
              return <ContactCell key={index} {...item} />;
            case isCheckbox(item):
              return (
                <SelectCell key={index}>
                  <Checkbox
                    checked={item.isSelected}
                    onChange={item.onSelectChange}
                    data-testid={item?.dataTestId}
                  />
                </SelectCell>
              );
            default:
              return (
                <Cell key={index} $alignment={cellAlignment}>
                  {item}
                </Cell>
              );
          }
        })}
      </Row>
    );
  },
);

type BaseHeading = {
  text: ReactNode;
  alignment?: 'left' | 'right' | 'center';
};

type SortableHeading = BaseHeading & {
  sort: 'asc' | 'desc' | undefined;
  onSortChange: (sort: 'asc' | 'desc') => void;
};

type CheckboxHeading = {
  checkboxState: CheckboxState;
  onSelectAllChange: () => void;
  dataTestId?: string;
};

type Heading = SortableHeading | CheckboxHeading | BaseHeading;

type ContactCellType = {
  type: 'contact';
  name: string;
  description?: string;
  image?: string;
  onContactClick?: () => void;
  status?: 'imported' | 'new';
};

type CheckboxCellType = {
  type: 'checkbox';
  isSelected: boolean;
  onSelectChange: () => void;
  dataTestId?: string;
};

type RowItem = ReactNode | ContactCellType | CheckboxCellType;

type Row = RowItem[];

interface ListProps {
  headings: Heading[];
  mobileHeadings: Heading[];
  rows: Row[];
  mobileRows: Row[];
  emptyStateMessage?: string;
  isCheckboxVisible?: boolean;
  loading: boolean;
}

const ColumnAlignmentContext = createContext<{ alignments: ('left' | 'right' | 'center')[] }>({
  alignments: [],
});

const COLUMN_OFFSET_WITH_CHECKBOX = 2;
const COLUMN_OFFSET_DEFAULT = 1;

const List = ({
  headings,
  mobileHeadings,
  rows = [],
  mobileRows,
  emptyStateMessage,
  isCheckboxVisible,
  loading,
}: ListProps) => {
  const isCheckboxPropProvided = isCheckboxVisible !== undefined;
  const isDesktop = useMediaQuery(media.greaterThan('desktop'));

  const displayedHeadings = isDesktop ? headings : mobileHeadings;
  const displayedRows = isDesktop ? rows : mobileRows;

  const adjustedColumnsCount = useMemo(
    () =>
      displayedHeadings.length -
      (isCheckboxPropProvided ? COLUMN_OFFSET_WITH_CHECKBOX : COLUMN_OFFSET_DEFAULT),
    [displayedHeadings, isCheckboxPropProvided],
  );

  const memoizedRows = useMemo(() => displayedRows, [displayedRows]);
  const columnAlignments = useMemo(
    () =>
      displayedHeadings.map(heading =>
        'alignment' in heading ? (heading?.alignment ?? 'left') : 'left',
      ),
    [displayedHeadings],
  );

  return (
    <ColumnAlignmentContext.Provider value={{ alignments: columnAlignments }}>
      <Container>
        {loading ? (
          <LoadingContainer columnsCount={adjustedColumnsCount} />
        ) : (
          <>
            <ListHeader
              headings={displayedHeadings}
              columnsCount={adjustedColumnsCount}
              isCheckboxVisible={isCheckboxVisible}
              isCheckboxPropProvided={isCheckboxPropProvided}
            />
            <RowContainer>
              {memoizedRows.length === 0 && emptyStateMessage && (
                <EmptyState>
                  <Text as="p" variant="labelMedium">
                    {emptyStateMessage}
                  </Text>
                </EmptyState>
              )}
              {memoizedRows.map((row, index) => (
                <ListRow
                  key={index}
                  row={row}
                  columnsCount={adjustedColumnsCount}
                  isCheckboxVisible={isCheckboxVisible}
                  isCheckboxPropProvided={isCheckboxPropProvided}
                />
              ))}
            </RowContainer>
          </>
        )}
      </Container>
    </ColumnAlignmentContext.Provider>
  );
};

export default List;
