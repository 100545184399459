import {
  SPACING_0_5,
  SPACING_1,
  SPACING_1_5,
  SPACING_2,
  SPACING_3,
  SPACING_4,
  SPACING_5,
  SPACING_10,
} from './spacings';

export const MOBILE_BUTTON_HORIZONTAL_PADDING = SPACING_2;
export const MOBILE_BUTTON_VERTICAL_PADDING = SPACING_1_5;
export const MOBILE_BUTTON_HORIZONTAL_GAP = SPACING_1;
export const MOBILE_PAGE_HORIZONTAL_PADDING = SPACING_2;
export const MOBILE_PAGE_VERTICAL_PADDING = SPACING_5;
export const MOBILE_PAGE_HORIZONTAL_GAP = '0rem';
export const MOBILE_NAVBAR_HORIZONTAL_GAP = '0rem';
export const MOBILE_BADGE_HORIZONTAL_PADDING = SPACING_1;
export const MOBILE_TOAST_HORIZONTAL_PADDING = '0rem';
export const MOBILE_TOAST_VERTICAL_PADDING = '0rem';
export const MOBILE_TOAST_HORIZONTAL_GAP = '0rem';
export const MOBILE_TOOLTIP_HORIZONTAL_PADDING = SPACING_0_5;
export const MOBILE_TOOLTIP_VERTICAL_PADDING = SPACING_0_5;
export const MOBILE_MENU_ITEM_VERTICAL_PADDING = SPACING_2;
export const MOBILE_MENU_ITEM_HORIZONTAL_GAP = SPACING_1;
export const MOBILE_MENU_HORIZONTAL_PADDING = SPACING_2;
export const MOBILE_MENU_HORIZONTAL_GAP = SPACING_4;
export const MOBILE_SUB_MENU_HORIZONTAL_GAP = SPACING_1;
export const MOBILE_MAIN_TABS_HORIZONTAL_GAP = SPACING_3;
export const MOBILE_STEPPER_ITEM_HORIZONTAL_GAP = SPACING_1;
export const MOBILE_STEPPER_HORIZONTAL_GAP = SPACING_3;
export const MOBILE_PROGRESS_BAR_VERTICAL_GAP = SPACING_0_5;
export const MOBILE_DIALOG_VERTICAL_PADDING = SPACING_2;
export const MOBILE_DIALOG_HORIZONTAL_PADDING = SPACING_2;
export const MOBILE_DIALOG_VERTICAL_GAP = SPACING_2;
export const MOBILE_BUTTON_PAIRING_FILLED_TO_FILLED = SPACING_2;
export const MOBILE_BUTTON_PAIRING_FILLED_TO_TEXT = SPACING_3;
export const MOBILE_BUTTON_PAIRING_TEXT_TO_TEXT = SPACING_5;
export const MOBILE_INFO_POPOVER_HORIZONTAL_PADDING = SPACING_3;
export const MOBILE_INFO_POPOVER_VERTICAL_PADDING = SPACING_3;
export const MOBILE_INFO_POPOVER_VERTICAL_GAP = SPACING_2;
export const MOBILE_INFO_MODAL_HORIZONTAL_PADDING_SM = SPACING_3;
export const MOBILE_INFO_MODAL_VERTICAL_PADDING_SM = SPACING_3;
export const MOBILE_INFO_MODAL_VERTICAL_GAP = SPACING_2;
export const MOBILE_INFO_MODAL_HORIZONTAL_PADDING_LG = SPACING_5;
export const MOBILE_INFO_MODAL_VERTICAL_PADDING_LG = SPACING_5;
export const MOBILE_BADGE_HORIZONTAL_GAP = SPACING_0_5;
export const MOBILE_INPUT_TEXT_HORIZONTAL_PADDING = '0rem';
export const MOBILE_INPUT_TEXT_HORIZONTAL_GAP = '0rem';
export const MOBILE_CHIP_HORIZONTAL_PADDING_LONG = SPACING_2;
export const MOBILE_CHIP_VERTICAL_PADDING_LONG = SPACING_1;
export const MOBILE_CHIP_HORIZONTAL_PADDING_SHORT = SPACING_2;
export const MOBILE_CHIP_HORIZONTAL_GAP_SHORT = SPACING_1;
export const DESKTOP_BUTTON_HORIZONTAL_PADDING = SPACING_2;
export const DESKTOP_BUTTON_VERTICAL_PADDING = SPACING_1_5;
export const DESKTOP_BUTTON_HORIZONTAL_GAP = SPACING_1;
export const DESKTOP_PAGE_HORIZONTAL_PADDING = SPACING_3;
export const DESKTOP_PAGE_VERTICAL_PADDING = SPACING_10;
export const DESKTOP_PAGE_HORIZONTAL_GAP = '0rem';
export const DESKTOP_NAVBAR_HORIZONTAL_GAP = SPACING_4;
export const DESKTOP_BADGE_HORIZONTAL_PADDING = SPACING_1;
export const DESKTOP_TOAST_HORIZONTAL_PADDING = SPACING_2;
export const DESKTOP_TOAST_VERTICAL_PADDING = SPACING_2;
export const DESKTOP_TOAST_HORIZONTAL_GAP = SPACING_2;
export const DESKTOP_TOOLTIP_HORIZONTAL_PADDING = SPACING_0_5;
export const DESKTOP_TOOLTIP_VERTICAL_PADDING = SPACING_0_5;
export const DESKTOP_MENU_ITEM_VERTICAL_PADDING = SPACING_2;
export const DESKTOP_MENU_ITEM_HORIZONTAL_GAP = SPACING_1;
export const DESKTOP_MENU_HORIZONTAL_PADDING = SPACING_3;
export const DESKTOP_MENU_HORIZONTAL_GAP = SPACING_4;
export const DESKTOP_SUB_MENU_HORIZONTAL_GAP = SPACING_2;
export const DESKTOP_MAIN_TABS_HORIZONTAL_GAP = SPACING_3;
export const DESKTOP_STEPPER_ITEM_HORIZONTAL_GAP = SPACING_1;
export const DESKTOP_STEPPER_HORIZONTAL_GAP = SPACING_3;
export const DESKTOP_PROGRESS_BAR_VERTICAL_GAP = SPACING_0_5;
export const DESKTOP_DIALOG_VERTICAL_PADDING = SPACING_5;
export const DESKTOP_DIALOG_HORIZONTAL_PADDING = SPACING_5;
export const DESKTOP_DIALOG_VERTICAL_GAP = SPACING_3;
export const DESKTOP_BUTTON_PAIRING_FILLED_TO_FILLED = SPACING_2;
export const DESKTOP_BUTTON_PAIRING_FILLED_TO_TEXT = SPACING_3;
export const DESKTOP_BUTTON_PAIRING_TEXT_TO_TEXT = SPACING_5;
export const DESKTOP_INFO_POPOVER_HORIZONTAL_PADDING = SPACING_3;
export const DESKTOP_INFO_POPOVER_VERTICAL_PADDING = SPACING_3;
export const DESKTOP_INFO_POPOVER_VERTICAL_GAP = SPACING_2;
export const DESKTOP_INFO_MODAL_HORIZONTAL_PADDING_SM = SPACING_3;
export const DESKTOP_INFO_MODAL_VERTICAL_PADDING_SM = SPACING_3;
export const DESKTOP_INFO_MODAL_VERTICAL_GAP = SPACING_2;
export const DESKTOP_INFO_MODAL_HORIZONTAL_PADDING_LG = SPACING_5;
export const DESKTOP_INFO_MODAL_VERTICAL_PADDING_LG = SPACING_5;
export const DESKTOP_BADGE_HORIZONTAL_GAP = SPACING_0_5;
export const DESKTOP_INPUT_TEXT_HORIZONTAL_PADDING = SPACING_2;
export const DESKTOP_INPUT_TEXT_HORIZONTAL_GAP = SPACING_1;
export const DESKTOP_CHIP_HORIZONTAL_PADDING_LONG = SPACING_2;
export const DESKTOP_CHIP_VERTICAL_PADDING_LONG = SPACING_1;
export const DESKTOP_CHIP_HORIZONTAL_PADDING_SHORT = SPACING_2;
export const DESKTOP_CHIP_HORIZONTAL_GAP_SHORT = SPACING_1;
