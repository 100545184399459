import {
  DANGER_700,
  GREY_600,
  PURPLE_400,
  RED_500,
  SUCCESS_300,
  SUCCESS_700,
  TEAL_300,
} from './colors';

export const SHADOW_1 = '0px 2px 4px rgba(49, 73, 164, 0.1)';
export const SHADOW_2_WITHOUT_BOTTOM_SPREAD =
  '0px 0px 3px rgba(49, 73, 164, 0.1), 0px 0px 2px rgba(49, 73, 164, 0.14)';
export const SHADOW_2 = `0px 6px 10px rgba(49, 73, 164, 0.08), ${SHADOW_2_WITHOUT_BOTTOM_SPREAD}`;
export const SHADOW_3 =
  '0px 12px 16px rgba(49, 73, 164, 0.08), 0px 0px 3px rgba(49, 73, 164, 0.1), 0px 0px 2px rgba(49, 73, 164, 0.14)';

export const FOCUS_SHADOW = `0 0 0 3px ${PURPLE_400}`;

export const FOCUS_SHADOW_SUCCESS = `inset 0 0 0 1px ${SUCCESS_700}, 0 0 0 3px ${SUCCESS_300}`;

export const FOCUS_SHADOW_FAILURE = `inset 0 0 0 1px ${DANGER_700}, 0 0 0 3px ${RED_500}`;

export const FOCUS_SHADOW_PRIMARY = `0 0 0 3px ${RED_500}`;

export const FOCUS_SHADOW_NEUTRAL = `0 0 0 3px ${GREY_600}`;

export const FOCUS_SHADOW_PR_ASSISTANT = `0 0 0 3px ${TEAL_300}`;

export const OVERLAY_SHADOW =
  '0px 12px 16px 0px rgba(49, 73, 164, 0.08), 0px 0px 3px 0px rgba(49, 73, 164, 0.10), 0px 0px 2px 0px rgba(49, 73, 164, 0.14)';
