import type { ReactChild } from 'react';

export type ClientSortValues = string | number | Date | undefined | null;
export type SortableCell = [ReactChild, ClientSortValues];

export const hasHelperSortValue = (cell: ClientSortValues | SortableCell): cell is SortableCell =>
  Array.isArray(cell);

const getSortOrderNumber = (direction: 'asc' | 'desc', value: number) =>
  direction === 'asc' ? value : value * -1;

export const sortDataTableRows =
  ({ column, direction }: { column: number; direction: 'asc' | 'desc' }) =>
  (
    rowA: (ClientSortValues | SortableCell)[],
    rowB: (ClientSortValues | SortableCell)[],
  ): number => {
    const tmpA = rowA[column];
    const tmpB = rowB[column];
    const a = hasHelperSortValue(tmpA) ? tmpA?.[1] : tmpA;
    const b = hasHelperSortValue(tmpB) ? tmpB?.[1] : tmpB;

    if (typeof a === 'number' && typeof b === 'number') {
      if (a < b) {
        return getSortOrderNumber(direction, -1);
      }
      if (a > b) {
        return getSortOrderNumber(direction, 1);
      }
    } else if (typeof a === 'number') {
      return getSortOrderNumber(direction, -1);
    } else if (typeof b === 'number') {
      return getSortOrderNumber(direction, 1);
    } else if (a instanceof Date && b instanceof Date) {
      return getSortOrderNumber(direction, a.getTime() - b.getTime());
    } else if (a instanceof Date) {
      return getSortOrderNumber(direction, -1);
    } else if (b instanceof Date) {
      return getSortOrderNumber(direction, 1);
    } else if (typeof a === 'string' && typeof b === 'string') {
      return direction === 'asc' ? b.localeCompare(a) : a.localeCompare(b);
    } else if (typeof a === 'string') {
      return getSortOrderNumber(direction, -1);
    } else if (typeof b === 'string') {
      return getSortOrderNumber(direction, 1);
    }

    return 0;
  };
