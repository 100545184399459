import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { DialogTrigger, ListBox } from 'react-aria-components';
import styled from 'styled-components';
import { GREY_600 } from '../../theme';
import { type Group, OpenButton, type Option, StyledPopover, useDropdownLogic } from './common';

const MultiSelectValue = styled.div<{ $disabled?: boolean }>`
  max-width: calc(100% - 1.5rem);
  margin-right: 1.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => (props.$disabled ? GREY_600 : 'inherit')};
`;

export interface MultiProps<T> {
  value: T[];
  onChange: (newValue: T[]) => void;
  selectedLabel?: string;
  isMulti: true;
  options: (Option<T> | Group<T>)[];
}

export type MultiSelectProps<T> = Omit<MultiProps<T>, 'isMulti'> & {
  label?: string;
  disabled?: boolean;
  labelId: string;
  hasContent?: boolean;
  'data-testid'?: string;
};

export const MultiSelect = <T,>({
  onChange,
  options,
  hasContent,
  disabled,
  value,
  labelId,
  selectedLabel,
  ...rest
}: MultiSelectProps<T>) => {
  const trigger = useRef<HTMLButtonElement>(null);
  const [triggerWidth, setTriggerWidth] = useState(0);
  const listBoxRef = useRef<HTMLDivElement>(null);

  const selectedSet = useMemo(() => {
    return new Set(value);
  }, [value]);

  const {
    isKeyCollapseButton,
    onCollapseButtonClick,
    open,
    renderGroupOrItem,
    renderOptions,
    setOpen,
    touchedRef,
    selectedKeys,
    disabledKeys,
    getValueFromId,
    isCheckboxButton,
  } = useDropdownLogic({
    hasContent,
    options,
    value: selectedSet,
    isMulti: true,
  });

  useLayoutEffect(() => {
    if (open) {
      setTriggerWidth(trigger.current?.offsetWidth ?? 0);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      listBoxRef.current?.focus();
    }
  }, [open]);

  const selectedLabels = useMemo(() => {
    if (selectedLabel) return [];
    const groupAccumulator = new Set<string>();
    const accumulator = new Set<string>();
    const claimedInGroup = new Set<string>();
    for (const groupOrOption of options) {
      if ('options' in groupOrOption) {
        const group = groupOrOption;
        if (group.options.length === 0) continue;

        const selectedInGroup = group.options.filter(o => selectedSet.has(o.value));
        if (selectedInGroup.length === group.options.filter(o => !o.disabled).length) {
          if (group.label !== undefined) {
            groupAccumulator.add(group.label);
          }
          group.options.forEach(o => claimedInGroup.add(o.label));
        } else {
          selectedInGroup.forEach(o => accumulator.add(o.label));
        }
      } else if (selectedSet.has(groupOrOption.value)) {
        accumulator.add(groupOrOption.label);
      }
    }
    return [...groupAccumulator, ...[...accumulator].filter(label => !claimedInGroup.has(label))];
  }, [options, selectedLabel, selectedSet]);

  return (
    <DialogTrigger isOpen={open} onOpenChange={setOpen}>
      <OpenButton
        ref={trigger}
        isDisabled={disabled}
        {...rest}
        onKeyDown={e => {
          if (e.key === 'ArrowDown') {
            setOpen(true);
          }
        }}
      >
        <MultiSelectValue $disabled={disabled}>
          {selectedLabel ?? (selectedLabels.length > 0 ? selectedLabels.join(', ') : <>&nbsp;</>)}
        </MultiSelectValue>
      </OpenButton>
      <StyledPopover
        crossOffset={2}
        style={{ '--trigger-width': `${triggerWidth}px` } as React.CSSProperties}
        $multi
      >
        <ListBox
          ref={listBoxRef}
          disabledKeys={disabledKeys}
          aria-labelledby={labelId}
          selectionMode="multiple"
          items={renderOptions}
          selectedKeys={selectedKeys}
          onSelectionChange={newSelection => {
            touchedRef.current = true;
            if (newSelection === 'all') {
              // We don't use this feature, type guard to get "Set" variant
              return;
            }
            const filterAwayIds = new Set<T>();
            const newValue = new Set<T>();
            for (const value of newSelection) {
              if (typeof value !== 'string') {
                continue;
              }
              if (isCheckboxButton(value)) {
                const group = options[Number(value.split('-')[1])] as Group<T>;
                if (group.options.filter(o => !o.disabled).every(o => selectedSet.has(o.value))) {
                  group.options.forEach(o => filterAwayIds.add(o.value));
                  continue;
                }
                group.options.filter(o => !o.disabled).forEach(o => newValue.add(o.value));
                continue;
              } else if (isKeyCollapseButton(value)) {
                onCollapseButtonClick(value);
                continue;
              }
              newValue.add(getValueFromId(value));
            }
            if (newSelection.size < selectedKeys.length) {
              // Removal, make sure to deselect duplicates too.

              const removedItem = selectedKeys.find(id => !newSelection.has(id));
              if (removedItem) {
                filterAwayIds.add(getValueFromId(removedItem));
              }
            }
            const returnValue = [...newValue].filter(v => !filterAwayIds.has(v));
            onChange(returnValue);
          }}
        >
          {option => renderGroupOrItem(option)}
        </ListBox>
      </StyledPopover>
    </DialogTrigger>
  );
};
