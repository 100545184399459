import { type FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { GREY_200, GREY_900 } from '../../theme';
import { media } from '../utils/media';

const TableHead = styled.thead`
  background: ${GREY_200};
  display: table-header-group;
  ${media.lessThan('tablet')} {
    display: none;
  }
`;

const Header = styled.th<{ $hasColumnSort: boolean; $numberOfHeadings: number }>`
  color: ${GREY_900};
  font-size: 0.8125rem;
  padding: 1rem 0.75rem;
  text-align: right;
  text-transform: uppercase;

  &:first-of-type {
    border-radius: 4px 0 0 4px;
    text-align: left;
    width: ${({ $numberOfHeadings }) => Math.max(40, 100 - 12.5 * $numberOfHeadings)}%;
  }
  &:last-of-type {
    border-radius: 0 4px 4px 0;
  }

  ${({ $hasColumnSort }) =>
    $hasColumnSort &&
    css`
      cursor: pointer;
      user-select: none;
    `}
`;

const HeaderContent = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
`;

const ArrowUp = styled.div`
  border-left: 0.375rem solid transparent;
  border-right: 0.375rem solid transparent;
  border-bottom: 0.375rem solid ${GREY_900};
  margin-left: 0.25rem;
`;

const ArrowDown = styled.div`
  border-left: 0.375rem solid transparent;
  border-right: 0.375rem solid transparent;
  border-top: 0.375rem solid ${GREY_900};
  margin-left: 0.25rem;
`;

type TableHeaderCellProps = {
  text: string;
  onSort?: (direction: 'desc' | 'asc') => void;
  sortOrder?: 'asc' | 'desc';
  numberOfHeadings: number;
};

const TableHeaderCell: FC<TableHeaderCellProps> = ({
  text,
  onSort,
  sortOrder,
  numberOfHeadings,
}) => (
  <Header
    onClick={
      onSort
        ? () => onSort(sortOrder === undefined || sortOrder === 'asc' ? 'desc' : 'asc')
        : undefined
    }
    $hasColumnSort={Boolean(onSort)}
    $numberOfHeadings={numberOfHeadings}
  >
    <HeaderContent>
      {text}
      {sortOrder === 'asc' && <ArrowUp />}
      {sortOrder === 'desc' && <ArrowDown />}
    </HeaderContent>
  </Header>
);

type Sort = { column: number; direction: 'asc' | 'desc' };

export type TableHeading = {
  text: string;
  serverSortAscending?: () => void;
  serverSortDescending?: () => void;
  sortable?: boolean;
};

export type TableHeaderProps = {
  trackSort?: (arg: { label: string; order: 'asc' | 'desc' }) => void;
  defaultSort?: Sort;
  headings: TableHeading[];
  onClientSort?: (sort: Sort) => void;
};

const TableHeader: FC<TableHeaderProps> = ({
  defaultSort,
  headings = [],
  onClientSort,
  trackSort,
}) => {
  const [sort, setSort] = useState<{ column: number; direction: 'asc' | 'desc' } | undefined>(
    defaultSort,
  );

  useEffect(() => {
    if (!sort) {
      setSort(defaultSort);
    }
  }, [defaultSort, sort]);

  return (
    <TableHead>
      <tr>
        {headings?.map(
          ({ text, serverSortAscending, serverSortDescending, sortable = true }, i) => (
            <TableHeaderCell
              key={text}
              text={text}
              sortOrder={sort?.column === i ? sort.direction : undefined}
              onSort={
                sortable && (onClientSort || serverSortAscending || serverSortDescending)
                  ? (direction: 'desc' | 'asc') => {
                      trackSort?.({
                        label: text,
                        order: direction,
                      });

                      if (onClientSort) {
                        onClientSort({ column: i, direction });
                      } else if (
                        serverSortDescending &&
                        (sort?.column !== i ||
                          sort?.direction === undefined ||
                          sort?.direction === 'asc')
                      ) {
                        serverSortDescending();
                      } else if (serverSortAscending) {
                        serverSortAscending();
                      }

                      setSort({ column: i, direction });
                    }
                  : undefined
              }
              numberOfHeadings={headings.length}
            />
          ),
        )}
      </tr>
    </TableHead>
  );
};

export default TableHeader;
