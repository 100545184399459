import HeaderCell from './HeaderCell';
import Row from './Row';
import type { ContentType } from './types';

export type TableHeaderProps = React.ThHTMLAttributes<HTMLTableHeaderCellElement> & {
  headings: React.ReactNode[];
  contentTypes: ContentType[];
};

const TableHeader = ({ headings, contentTypes, ...props }: TableHeaderProps) => (
  <thead>
    <Row>
      {headings.map((heading, index) => (
        <HeaderCell key={index} $contentType={contentTypes[index]} {...props}>
          {heading}
        </HeaderCell>
      ))}
    </Row>
  </thead>
);

export default TableHeader;
