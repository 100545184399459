import { useClickOutside } from '@mnd-frontend/hooks';
import { type ReactElement, useId, useRef, useState } from 'react';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { GREY_200, GREY_400, SHADOW_1 } from '../../theme';
import { Button } from '../Button';
import Icons from '../Icons';

interface Action {
  icon?: ReactElement;
  text: string;
  onClick?: () => void;
  href?: string;
  'data-testid'?: string;
  disabled?: boolean;
}

const Wrapper = styled.div`
  position: relative;
  white-space: nowrap;
`;

const PopupWrapper = styled.div`
  position: absolute;
  background: white;
  padding: 0.5rem;
  box-shadow: ${SHADOW_1};
  right: 0;
  top: -50%;
  transform: translateY(50%);
`;
const PopupButtonWrapper = styled.div`
  position: relative;
  border-color: ${GREY_400};
  border-style: solid;
  border-width: 0;
  &:not(:last-child) {
    border-width: 0 0 1px 0;
  }
`;
const PopupButton = styled.button`
  background: none;
  font-family: inherit;
  text-align: center;
  border: 0;
  width: 100%;
  padding: 0.75rem 1rem;
  display: block;
  color: inherit;
  &:hover {
    background-color: ${GREY_200};
    color: inherit;
  }
  &[aria-disabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }
`;
const PopupButtonContent = styled.div<{ $hasIcon: boolean }>`
  white-space: nowrap;
  ${props =>
    props.$hasIcon &&
    css`
      display: grid;
      align-items: center;
      grid-template-columns: 1.5rem 1fr;
      gap: 0.5rem;

      > :first-child {
        width: 1.5rem;
        height: 1.5rem;
      }
    `}
`;

export const DropdownButton = ({
  actions,
  ...buttonProps
}: { actions: Action[] } & Omit<Parameters<typeof Button>[0], 'type' | 'onClick' | 'icon'> & {
    children: string;
  }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const menuId = useId();

  useClickOutside(
    wrapperRef,
    () => {
      setOpen(false);
    },
    open,
  );

  return (
    <Wrapper ref={wrapperRef}>
      <Button
        {...buttonProps}
        onClick={() => {
          setOpen(o => !o);
        }}
        type="button"
        aria-haspopup="menu"
        aria-controls={menuId}
        aria-expanded={open.toString()}
        trailingIcon={<Icons.ChevronDown />}
      />
      {open && (
        <PopupWrapper id={menuId} role="menu">
          {actions.map((action, i) => (
            <PopupButtonWrapper key={i}>
              <PopupButton
                {...((action.href
                  ? {
                      as: Link,
                      to: action.href,
                    }
                  : {}) as any)}
                onClick={() => {
                  setOpen(false);
                  action.onClick?.();
                }}
                data-testid={action['data-testid']}
                aria-disabled={!!action.disabled}
              >
                <PopupButtonContent $hasIcon={!!action.icon}>
                  {action.icon}
                  <span>{action.text}</span>
                </PopupButtonContent>
              </PopupButton>
            </PopupButtonWrapper>
          ))}
        </PopupWrapper>
      )}
    </Wrapper>
  );
};
