import React from 'react';
import styled, { css } from 'styled-components';
import { H4 } from '../../Typo';
import { media } from '../../utils/media';

const Layout = styled.div`
  ${media.greaterThan('tablet')} {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1.5rem;
    margin-top: -1.5rem;
  }
`;

const LayoutItem = styled.div`
  width: 100%;

  & + & {
    margin-top: 1.5rem;
  }

  ${media.greaterThan('tablet')} {
    margin-top: 1.5rem;
    max-width: calc(50% - 1.5rem);
    margin-right: 1.5rem;
  }
`;

type SectionComponentProps = {
  /** Title of the card section */
  title?: string;

  /** Use multicolumn layout for large devices */
  multiColumn?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const SectionComponent: React.FunctionComponent<SectionComponentProps> = ({
  multiColumn,
  children,
  title,
  ...props
}) => {
  const titleMarkup = typeof title === 'string' ? <H4>{title}</H4> : title;

  const content = React.useMemo(
    () =>
      multiColumn ? (
        <Layout>
          {React.Children.map(children, (child: any) => child && <LayoutItem>{child}</LayoutItem>)}
        </Layout>
      ) : (
        children
      ),
    [children, multiColumn],
  );

  return (
    <div {...props}>
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      <>
        {titleMarkup}
        {content}
      </>
    </div>
  );
};

const Section = styled(SectionComponent)`
  padding: 0 1.5rem;
  margin: 1.5rem 0;

  ${({ title }) =>
    title &&
    css`
      & > *:first-child {
        margin-bottom: 0.5rem;
      }
    `}
`;

export default Section;
