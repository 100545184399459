import { toBoolean } from '@mnd-frontend/ts';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  Button as AriaButton,
  Collection,
  Header,
  ListBoxItem,
  Popover,
  Section,
} from 'react-aria-components';
import styled from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import {
  GREY_300,
  GREY_500,
  GREY_600,
  GREY_800,
  GREY_900,
  NEUTRAL_WHITE,
  PURPLE_600,
  RADIUS_SM,
  SIZING_5,
  SPACING_2,
} from '../../theme';
import { type CheckboxState, CheckboxVisual } from '../Checkbox';
import Icons from '../Icons';
import { Text } from '../Text';

export interface Option<T> {
  label: string;
  disabled?: boolean;
  icon?: keyof typeof Icons;
  value: T;
}

export interface Group<T> {
  label?: string;
  value?: undefined;
  collapsable?: boolean;
  options: Option<T>[];
}
export const StyledHeader = styled(Header)<{ $isMulti?: boolean }>`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 1rem 1rem;
  padding-left: ${props => props.$isMulti && '3rem'};
  > span {
    font-size: 0.75rem;
    line-height: 1;
    color: ${GREY_800};
    font-weight: 500;
  }
`;

const CheckboxWrapper = styled.div`
  line-height: 0;
  position: relative;
  &[data-selected='true'] ${CheckboxVisual} {
    border-color: ${props => props.theme.colorBackgroundCheckboxTrueEnabled};
    background: ${props => props.theme.colorBackgroundCheckboxTrueEnabled};
  }
`;
export const StyledPopover = styled(Popover)<{ $multi?: boolean }>`
  border: 1px solid ${PURPLE_600};
  border-radius: ${RADIUS_SM};
  background: ${NEUTRAL_WHITE};
  min-width: calc(var(--trigger-width) + 4px);
  overflow-x: hidden;
  max-width: 90vw;
  transform: translateX(-2px);
  overflow-y: auto;

  .react-aria-ListBoxItem {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: ${SPACING_2};
    padding: 0.5rem 1rem;
    min-height: 2.75rem;
    color: ${GREY_900};
    & > *:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &[data-disabled] {
      color: ${GREY_600};
      cursor: default;
    }
    &:not([data-disabled]):hover {
      background: ${GREY_300};

      &[data-selected] ${CheckboxVisual} {
        border-color: ${props => props.theme.colorBackgroundCheckboxTrueHover};
        background: ${props => props.theme.colorBackgroundCheckboxTrueHover};
      }
    }

    &:focus-visible {
      outline: 0;
      background: ${GREY_300};
      &[data-selected] ${CheckboxVisual} {
        border-color: ${props => props.theme.colorBackgroundCheckboxTrueHover};
        background: ${props => props.theme.colorBackgroundCheckboxTrueHover};
      }
    }

    &[data-selected] ${CheckboxVisual} {
      border-color: ${props => props.theme.colorBackgroundCheckboxTrueEnabled};
      background: ${props => props.theme.colorBackgroundCheckboxTrueEnabled};
    }
  }
  .react-aria-Section:not(:has(> ${StyledHeader})) {
    border-top: 1px solid ${GREY_500};
  }
  .react-aria-Section {
    position: relative;
    ${StyledHeader} ~ .react-aria-ListBoxItem {
      padding: 0.5rem 1.5rem;
    }
  }

  &&&&& {
    [data-checkbox] {
      position: absolute;
      left: 1rem;
      top: 0;
      padding: 0;

      &:hover,
      &:focus {
        background: none;
        > ${CheckboxWrapper}[data-selected="true"] ${CheckboxVisual} {
          border-color: ${props => props.theme.colorBackgroundCheckboxTrueHover};
          background: ${props => props.theme.colorBackgroundCheckboxTrueHover};
        }
        > ${CheckboxWrapper}:not([data-selected="true"]) ${CheckboxVisual} {
          border-color: ${props => props.theme.colorBorderCheckboxFalseHover};
          background: ${props => props.theme.colorBackgroundCheckboxFalseHover};
        }
      }
    }
    [data-collapsebutton] {
      position: absolute;
      right: 0.5rem;
      top: 0;
      padding: 0 0.5rem;
    }
  }
`;

export const OpenButton = styled(AriaButton)`
  background: none;
  padding: 0 ${SPACING_2};
  border: 0;
  height: ${SIZING_5};
  width: 100%;
  text-align: left;
  &:focus-visible {
    outline: 0;
  }
`;

const CheckboxIndicator = ({ state }: { state?: CheckboxState }) => {
  return (
    <CheckboxWrapper data-selected={state && state !== 'unchecked'}>
      <CheckboxVisual>
        {state === 'indeterminate' ? <Icons.Minus /> : <Icons.Check />}
      </CheckboxVisual>
    </CheckboxWrapper>
  );
};
export const IconWrapper = styled.span``;

// Not a component to get `key` set properly like react-aria-components wants
const getSingleItem = <T,>({
  option,
  isMulti,
}: {
  option: (Option<T> | Option<T | null>) & { id: string };
  isMulti?: boolean;
}) => {
  const Icon = option.icon ? Icons[option.icon] : null;
  return (
    <ListBoxItem key={option.id} id={option.id} textValue={option.label}>
      {isMulti && <CheckboxIndicator />}
      {Icon && (
        <IconWrapper>
          <Icon $size="xs" $color="colorIconsDefault" />
        </IconWrapper>
      )}
      <Text as="span" variant="labelSmall">
        {option.label}
      </Text>
    </ListBoxItem>
  );
};

export const useDropdownLogic = <T,>({
  options,
  hasContent,
  value,
  isMulti,
}: {
  hasContent?: boolean;
  isMulti?: boolean;
} & (
  | {
      options: (Option<T> | Group<T>)[];
      value: Set<T>;
    }
  | {
      options: (Option<T | null> | Group<T | null>)[];
      value: T | null;
    }
)) => {
  const [open, setOpen] = useState(false);

  const touchedRef = useRef(false);

  const [expandedSections, setExpandedSections] = useState<{ [index: number]: boolean }>(() => {
    if (hasContent && !isMulti) {
      const selectedSet = value instanceof Set ? value : new Set([value]);
      const preExpanded = options.findIndex(option =>
        'options' in option ? option.options.some(o => selectedSet.has(o.value)) : false,
      );
      if (preExpanded >= 0) {
        return { [preExpanded]: true };
      }
    }
    return {};
  });
  useLayoutEffect(() => {
    const closedByCollapseButtonClick =
      !open && Object.keys(expandedSections).length > 0 && touchedRef.current;
    if (closedByCollapseButtonClick) {
      setOpen(true);
    }

    // We explicitly only want to take action straight after expand/collapse.
    // This was the best hack I could think of.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedSections]);
  const { t } = useTranslation();

  const { renderOptions, selectedKeys, disabledKeys, idToValue } = useMemo(() => {
    const idToValue: { [id: string]: T | null } = {};
    const selectedKeys: string[] = [];
    const disabledKeys: string[] = [];

    const renderOptions = options
      .map((option, i) => {
        if ('options' in option) {
          if (option.options.length === 0) return null;
          let innerOptions = option.options.map((o, j) => {
            const id = `${i}:${j}`;
            idToValue[id] = o.value;
            return { ...o, id };
          });
          let checkboxStatus: CheckboxState = 'unchecked';

          if (value instanceof Set) {
            const selectedItems = innerOptions.filter(o => value.has(o.value as T));
            selectedItems.forEach(item => selectedKeys.push(item.id));

            const selectedCount = selectedItems.length;
            checkboxStatus =
              selectedCount === 0
                ? 'unchecked'
                : selectedCount === innerOptions.filter(o => !o.disabled).length
                  ? 'checked'
                  : 'indeterminate';
          } else {
            const selectedItem = innerOptions.find(o => o.value === value);
            if (selectedItem) {
              selectedKeys.push(selectedItem.id);
            }
          }
          disabledKeys.push(...innerOptions.filter(o => o.disabled).map(o => o.id));
          if (option.collapsable && !expandedSections[i]) {
            innerOptions = [];
          }

          return { ...option, i, options: innerOptions, checkboxStatus };
        }
        const id = `${i}`;
        idToValue[id] = option.value;
        if (option.disabled) {
          disabledKeys.push(id);
        }
        if (value instanceof Set ? value.has(option.value as T) : value === option.value) {
          selectedKeys.push(id);
        }
        return { ...option, id, i };
      })
      .filter(toBoolean);

    return { selectedKeys, renderOptions, disabledKeys, idToValue };
  }, [expandedSections, value, options]);

  const getValueFromId = useCallback(<E,>(id: string) => idToValue[id] as E, [idToValue]);
  return {
    open,
    setOpen,
    getValueFromId,
    selectedKeys,
    touchedRef,
    disabledKeys,
    renderOptions,
    isKeyCollapseButton: (key: string) => key.startsWith('___collapsebutton-'),
    onCollapseButtonClick: (key: string) => {
      const i = Number(key.split('-')[1]);
      const group = options[i];
      setExpandedSections(e => ({ ...e, [i]: !e[i] }));
      return group as Group<T>;
    },
    isCheckboxButton: (key: string) => key.startsWith('___checkbox-'),
    renderGroupOrItem: (option: (typeof renderOptions)[number]) => {
      if ('options' in option) {
        const group = option;
        return (
          <Section key={`section${group.i}`} id={`section${group.i}`}>
            {group.label && (
              <>
                <StyledHeader $isMulti={isMulti}>
                  <span>{group.label}</span>
                </StyledHeader>
                {isMulti && (
                  <ListBoxItem data-checkbox id={`___checkbox-${option.i}`} textValue={group.label}>
                    <CheckboxIndicator state={group.checkboxStatus} />
                  </ListBoxItem>
                )}
                {group.collapsable ? (
                  <ListBoxItem
                    data-collapsebutton
                    id={`___collapsebutton-${option.i}`}
                    textValue={
                      expandedSections[option.i]
                        ? t('card_action-collapse')
                        : t('card_action-expand')
                    }
                  >
                    <span
                      aria-label={
                        expandedSections[option.i]
                          ? t('card_action-collapse')
                          : t('card_action-expand')
                      }
                    >
                      {expandedSections[option.i] ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
                    </span>
                  </ListBoxItem>
                ) : null}
              </>
            )}

            <Collection items={group.options}>
              {o =>
                getSingleItem({
                  option: o,
                  isMulti,
                })
              }
            </Collection>
          </Section>
        );
      }
      return getSingleItem({
        option,
        isMulti,
      });
    },
  };
};
