import styled, { css, keyframes } from 'styled-components';
import { GREY_400, RADIUS_MD } from '../../../theme';

const fadeIn = keyframes`
  from { 
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const SkeletonBase = styled.div<{ $loading: boolean }>`
  ${({ $loading }) =>
    $loading
      ? css`
          width: fit-content;
          background: ${GREY_400};
          border-radius: ${RADIUS_MD};
          color: transparent;
          pointer-events: none;
          &::before,
          &::after,
          * {
            visibility: hidden;
          }
        `
      : css`
          background: unset;
          animation: ${fadeIn} 0.45 ease-out;
        `}
`;

type SkeletonProps = {
  loading: boolean;
  children?: React.ReactNode;
};

const Skeleton = ({ loading, children }: SkeletonProps) => {
  return <SkeletonBase $loading={loading}>{children}</SkeletonBase>;
};

export default Skeleton;
