import type { ClipboardEvent } from 'react';
import { type GroupBase, type Options, type OptionsOrGroups, components } from 'react-select';
import Creatable from 'react-select/creatable';
import {
  type MultiSelectProps,
  type OptionType,
  defaultComponents,
  defaultStyles,
} from '../GenericMultiSelect';

const Input = (inputProps: any) => (
  <components.Input
    onPaste={inputProps?.selectProps?.onPaste}
    data-testid="add-categories-field"
    {...inputProps}
    maxLength={inputProps?.selectProps?.maxLength}
  />
);

const noCreateFunc = () => false;
const isValidNewOption = (
  inputValue: string,
  _selectValue: Options<OptionType>,
  selectOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>,
) => {
  const trimmed = inputValue.trim();
  const withFlattenedGroups = selectOptions.flatMap((curr: OptionType | GroupBase<OptionType>) => {
    if ('options' in curr) return curr.options;
    return curr;
  });
  return (
    trimmed.length > 0 &&
    !withFlattenedGroups.find(v => v.label === trimmed) &&
    !withFlattenedGroups.find(v => v.value === trimmed)
  );
};

const TagSelect = ({
  components = {},
  formatCreateLabel,
  menuPortalTarget,
  isSearchable,
  maxLength,
  ...props
}: MultiSelectProps & {
  dismissAriaLabel?: string;
  'aria-label'?: string;
  shadow?: boolean;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  maxLength?: number;
  onPaste?: (event: ClipboardEvent<HTMLInputElement>) => void;
  operator?: string;
  'aria-labelledby'?: string;
}) => (
  <Creatable
    isValidNewOption={formatCreateLabel ? isValidNewOption : noCreateFunc}
    menuPortalTarget={menuPortalTarget ?? document.body}
    isSearchable={isSearchable ?? true}
    formatCreateLabel={formatCreateLabel}
    isMulti
    isClearable={false}
    styles={{
      ...defaultStyles,
    }}
    components={{
      ...defaultComponents,
      Input,
      ...components,
    }}
    {...props}
  />
);

export default TagSelect;
