import type { FC } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import { GREY_200, GREY_800, GREY_900, NEUTRAL_WHITE } from '../../theme';
import media from '../utils/media';

const TableFoot = styled.tfoot`
  background: ${GREY_200};
  display: table-footer-group;
  ${media.lessThan('tablet')} {
    display: none;
  }
  &:after {
    background: ${NEUTRAL_WHITE};
    line-height: 1.5rem;
    content: '.';
    color: ${NEUTRAL_WHITE};
    display: block;
  }
`;

const Cell = styled.td<{ $singleRow: boolean }>`
  color: ${GREY_800};
  font-size: 0.8125rem;
  padding: ${props => (props.$singleRow ? '1rem 1.5rem' : '0 1.5rem 1rem 0.75rem')};
  text-align: right;
  font-weight: 700;
  &:first-of-type {
    border-radius: 0 0 0 0.25rem;
    text-align: left;
    ${props =>
      props.$singleRow &&
      css`
        padding-left: 0.75rem;
        line-height: 1.5rem;
        text-transform: uppercase;
        color: ${GREY_900};
      `}
  }
  &:last-of-type {
    border-radius: 0 0 0.25rem 0;
  }
`;

const Header = styled.th`
  color: ${GREY_900};
  padding-left: 0.75rem;
  padding-top: 1rem;
  text-align: left;
  text-transform: uppercase;
  border-radius: 0.25rem 0 0 0;
`;

const EmptyDataCell = styled.td`
  &:last-of-type {
    border-radius: 0 0.25rem 0 0;
  }
`;

export type TableFooterProps = {
  footerItems?: React.ReactNode[];
  singleFooterRow?: boolean;
};

const TableFooter: FC<TableFooterProps> = ({ footerItems, singleFooterRow = false }) => {
  const { t } = useTranslation();
  const numberOfItemsToCompensate = (footerItems && footerItems.length - 1) ?? 0;

  return (
    <TableFoot>
      {!singleFooterRow && (
        <tr>
          <Header>{t('common_total')}:</Header>
          {[...Array(numberOfItemsToCompensate)].map((_, i) => (
            <EmptyDataCell key={i}>&nbsp;</EmptyDataCell>
          ))}
        </tr>
      )}
      <tr>
        {singleFooterRow && <Cell $singleRow>{t('common_total')}:</Cell>}
        {footerItems?.map((item, i) => (
          <Cell key={i} $singleRow={singleFooterRow}>
            {item}
          </Cell>
        ))}
      </tr>
    </TableFoot>
  );
};
export default TableFooter;
