import type { ChangeEventHandler, ReactNode } from 'react';
import styled from 'styled-components';
import { SPACING_1 } from '../../theme';

const RadioText = styled.span<{ $disabled?: boolean }>`
  color: ${props =>
    props.$disabled ? props.theme.colorTextBodyDisabled : props.theme.colorTextBodyPrimary};
`;

const Label = styled.label`
  position: relative;
  user-select: none;
  display: flex;
  cursor: pointer;
  gap: ${SPACING_1};
  align-items: center;
`;

const Background = styled.div`
  border-radius: 100%;
  border: 1px solid ${props => props.theme.colorBorderRadioButtonFalseEnabled};
  background: white;
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  flex-grow: 0;
  top: 0;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 0;
`;

const RadioWrapper = styled.div`
  position: relative;
`;

const Fill = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  transform: translate(-50%, -50%);
  transition: opacity 100ms ease-in-out;
  pointer-events: none;
  border: 0.25rem solid ${props => props.theme.colorBackgroundRadioButtonTrueEnabled};
  opacity: 0;
`;

const HiddenInput = styled.input`
  z-index: 1;
  opacity: 0;
  display: block;
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  margin: 0;
  appearance: none;

  &:enabled:hover:not(:checked) {
    ~ ${Background} {
      border-color: ${props => props.theme.colorBorderRadioButtonFalseHover};
    }
  }
  &:focus:checked ~ ${Fill} {
    // Targeting border-color inside the whatintent selector somehow loses the correct theme connection
    color: ${props => props.theme.colorBackgroundRadioButtonTrueFocused};
    [data-whatintent='keyboard'] & {
      border-color: currentColor;
    }
  }

  &:checked ~ ${Fill} {
    opacity: 1;
  }

  &:disabled {
    cursor: auto;
    ~ ${Background} {
      border-color: ${props => props.theme.colorBorderRadioButtonFalseDisabled};
    }
  }
  &:disabled ~ ${Fill} {
    border-color: ${props => props.theme.colorBackgroundRadioButtonTrueDisabled};
  }
`;

export interface RadioButtonProps<T> {
  id?: string;
  disabled?: boolean;
  label?: ReactNode;
  value: T;
  onChange: ChangeEventHandler<HTMLInputElement>;
  name: string;
  checked: boolean;
  'aria-label'?: string;
}

export const RadioButton = <T extends { toString: () => string }>({
  checked,
  disabled,
  name,
  onChange,
  label,
  value,
  ...props
}: RadioButtonProps<T>) => {
  return (
    <Label as={label ? undefined : 'div'}>
      <RadioWrapper>
        <HiddenInput
          {...props}
          type="radio"
          onChange={onChange}
          name={name}
          value={value.toString()}
          checked={checked}
          aria-checked={checked}
          disabled={disabled}
        />
        <Background />
        <Fill />
      </RadioWrapper>
      {label && <RadioText $disabled={disabled}>{label}</RadioText>}
    </Label>
  );
};
