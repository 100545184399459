import { type ReactNode, useEffect, useRef, useState } from 'react';
import { Modal, ModalOverlay } from 'react-aria-components';
import styled, { keyframes } from 'styled-components';

const ANIMATION_DURATION = 125;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }  to {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }  to {
    opacity: 0;
  }
`;
const Backdrop = styled(ModalOverlay)<{ $backdropOpacity: number }>`
  background: ${({ $backdropOpacity }) => `rgba(0, 0, 0, ${$backdropOpacity})`};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &[data-entering] {
    animation: ${fadeIn} ${ANIMATION_DURATION}ms forwards;
  }
  &[data-exiting] {
    animation: ${fadeOut} ${ANIMATION_DURATION}ms forwards;
  }
`;

export const UiLibModal = ({
  children,
  open,
  backdropOpacity = 0.7,
}: {
  children: ReactNode;
  open: boolean;
  backdropOpacity?: number;
}) => {
  const [hasAnimatedOut, setHasAnimatedOut] = useState(true);
  const lastOpenRef = useRef(open);

  useEffect(() => {
    if (open) {
      setHasAnimatedOut(false);
    } else if (lastOpenRef.current) {
      const timeout = setTimeout(() => {
        setHasAnimatedOut(true);
        return () => {
          clearTimeout(timeout);
        };
      }, ANIMATION_DURATION);
    }
    lastOpenRef.current = open;
  }, [open]);

  if (hasAnimatedOut && !open) return null;

  return (
    <Backdrop
      data-backdrop
      isOpen={open}
      isEntering={open}
      isExiting={!open && !hasAnimatedOut}
      $backdropOpacity={backdropOpacity}
    >
      {/*
       * React event propagation is based on the React component tree.
       * This means that even if we have a portal, the event will still propagate through the React component tree.
       * This can cause issues where the modal component is wrapped inside a clickable element, like in the content list.
       * This div prevents react event propagation outside the modal.
       */}
      <div onClick={e => e.stopPropagation()}>
        <Modal>{children}</Modal>
      </div>
    </Backdrop>
  );
};
