import type { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { RADIUS_LG, SIZING_1, SIZING_2, SPACING_1, SPACING_2, SPACING_5 } from '../../theme';
import { Button } from '../Button';
import Icons from '../Icons';
import { Text } from '../Text';
import media from '../utils/media';

const Wrapper = styled.aside<{ $newNavigationDisabled: boolean }>`
  background-color: ${props => props.theme.colorBackgroundSystemInfoDefault};
  display: flex;
  gap: ${SPACING_5};
  justify-content: center;
  min-height: ${props => props.theme.sizingSystemInfoHeight};
  position: relative;
  z-index: 2;
  max-width: 100%;
  overflow: hidden;
  margin-top: var(--header-height-mobile);
  ${media.greaterThan('navigationBreakpoint')} {
    margin-top: unset;
    border-top-right-radius: ${RADIUS_LG};
    border-top-left-radius: ${RADIUS_LG};
  }
  ${({ $newNavigationDisabled }) =>
    $newNavigationDisabled &&
    css`
      border-top-right-radius: unset;
      border-top-left-radius: unset;
    `}
`;

const TextPart = styled.div`
  display: flex;
  gap: ${SPACING_1};
  align-items: center;
  color: ${props => props.theme.colorTextBodyOnDark};
  ${Icons.CircleInfo} {
    flex-shrink: 0;
  }
  > p {
    padding: ${SIZING_2} 0;
    ${media.lessThan('phablet')} {
      padding: ${SIZING_1} 0;
    }
  }
`;

const Body = styled.div<{ $newNavigationDisabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.375rem 0 0.75rem; //Match with menu
  ${({ $newNavigationDisabled }) =>
    $newNavigationDisabled
      ? css`
          width: var(--page-width, '100%');
          max-width: 100%;
        `
      : css`
          width: 100%;
        `}
  gap: ${SPACING_5};
  ${media.lessThan('tablet')} {
    gap: ${SPACING_2};
  }
  ${media.lessThan('phablet')} {
    gap: 0;
    flex-direction: column;
  }
`;

export const SystemInfo = ({
  children,
  newNavigationDisabled,
  action,
}: {
  children: ReactNode;
  newNavigationDisabled: boolean;
  action?: {
    text: string;
    onClick: () => void;
    loading?: boolean;
  };
}) => {
  return (
    <Wrapper $newNavigationDisabled={newNavigationDisabled}>
      <Body $newNavigationDisabled={newNavigationDisabled}>
        <TextPart>
          <Icons.CircleInfo $size="xs" $color="colorIconsOnDark" />
          <Text as="p" variant="body">
            {children}
          </Text>
        </TextPart>
        {action && (
          <Button
            onDark
            type="button"
            configuration="text"
            onClick={action.onClick}
            loading={action.loading}
          >
            {action.text}
          </Button>
        )}
      </Body>
    </Wrapper>
  );
};
