import { useMediaQuery, useStateWithOverwrite } from '@mnd-frontend/hooks';
import type { MaybeEmptyObject } from '@mnd-frontend/ts';
import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import { SPACING_1_5 } from '../../theme';
import { Button } from '../Button';
import EmptyState from '../EmptyState';
import { Spinner } from '../Spinner';
import media from '../utils/media';
import TableBody from './TableBody';
import TableFooter, { type TableFooterProps } from './TableFooter';
import TableHeader, { type TableHeaderProps } from './TableHeader';
import TableMobileBody from './TableMobileBody';
import TableMobileFooter from './TableMobileFooter';
import TableMobileHeader from './TableMobileHeader';
import {
  type ClientSortValues,
  type SortableCell,
  hasHelperSortValue,
  sortDataTableRows,
} from './TableUtils';

const TableWrapper = styled.div<{ $horizontalScroll?: boolean }>`
  width: calc(100% - 1.6rem);
  margin-left: 0.8rem;
  ${media.greaterThan('tablet')} {
    overflow-x: auto;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  overflow: hidden;

  ${media.lessThan('tablet')} {
    table-layout: fixed;
  }
`;

const SpinnerWrapper = styled.div`
  padding: ${SPACING_1_5} 0;
`;

const ShowMoreWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 0.75rem;
  margin-right: 1.625rem;

  ${media.lessThan('tablet')} {
    table-layout: fixed;
    margin-left: 1.5rem;
  }
`;

export type DataTableProps = {
  trackSort?: (arg: { label: string; order: 'asc' | 'desc' }) => void;
  trackShowMore?: () => void;
  empty?: React.ReactNode;
  loading?: boolean;
  hideHeaderOnMobile?: boolean;
  preserveLastColumnOnMobile?: boolean;
  avoidLineOverflow?: boolean;
  /**
   * Each cell requires a value which is possible to sort upon.
   * This can either be the cell value itself or a helper value.
   * If a helper value is used, the input should be an array where
   * the first input is the display value and the second is the
   * helper value to sort upon.
   */
  rows: (ClientSortValues | SortableCell)[][];
  errorMessage?: string;
  emptyMessage?: {
    title?: string;
    description?: string;
  };
} & MaybeEmptyObject<
  | {
      rowsToLoad: number;
      totalRows: number;
      onLoadMore: () => void;
    }
  | {
      externalPaginationEnableServerSort: boolean;
    }
> &
  TableHeaderProps &
  TableFooterProps;

const DataTable = ({
  trackSort,
  trackShowMore,
  headings = [],
  rows = [],
  footerItems,
  loading = false,
  empty,
  defaultSort,
  singleFooterRow,
  hideHeaderOnMobile = false,
  preserveLastColumnOnMobile = false,
  emptyMessage,
  errorMessage,
  avoidLineOverflow,
  ...restProps
}: DataTableProps) => {
  const { t } = useTranslation();

  const [clientSortedRows, setClientSortedRows] = useStateWithOverwrite(
    useMemo(
      () => rows.map(row => row.map(cell => (hasHelperSortValue(cell) ? cell[0] : cell))),
      [rows],
    ),
  );

  const isMobile = useMediaQuery(media.lessThan('tablet'));

  const isEmpty = !rows.length && !clientSortedRows?.length && !clientSortedRows[0]?.length;
  const showShowMore = 'totalRows' in restProps && restProps.rowsToLoad > 0;
  const externalPaginationEnableServerSort =
    'externalPaginationEnableServerSort' in restProps &&
    restProps.externalPaginationEnableServerSort;
  const sortingEnabled = headings.some(
    heading => heading.serverSortAscending || heading.serverSortDescending,
  );

  return (
    <>
      <TableWrapper>
        <Table>
          {!isMobile && (
            <TableHeader
              trackSort={trackSort}
              defaultSort={defaultSort}
              headings={loading && isEmpty ? [{ text: '' }] : headings}
              onClientSort={
                sortingEnabled && !showShowMore && !externalPaginationEnableServerSort
                  ? sort =>
                      setClientSortedRows(
                        [...rows]
                          .sort(sortDataTableRows(sort))
                          .map(row => row.map(cell => (hasHelperSortValue(cell) ? cell[0] : cell))),
                      )
                  : undefined
              }
            />
          )}
          {isMobile && !hideHeaderOnMobile && sortingEnabled && (
            <TableMobileHeader
              trackSort={trackSort}
              defaultSort={defaultSort}
              headings={headings}
              onClientSort={
                !showShowMore && !externalPaginationEnableServerSort
                  ? sort =>
                      setClientSortedRows(
                        [...rows]
                          .sort(sortDataTableRows(sort))
                          .map(row => row.map(cell => (hasHelperSortValue(cell) ? cell[0] : cell))),
                      )
                  : undefined
              }
            />
          )}
          {clientSortedRows.length > 0 && (
            <>
              {isMobile ? (
                <TableMobileBody
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  rows={clientSortedRows}
                  headings={headings}
                  hideHeaderOnMobile={hideHeaderOnMobile}
                  preserveLastColumnOnMobile={preserveLastColumnOnMobile}
                />
              ) : (
                <TableBody
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  rows={clientSortedRows}
                  keepLastRowBorder={'rowsToLoad' in restProps && restProps.rowsToLoad > 0}
                  avoidLineOverflow={avoidLineOverflow}
                />
              )}
              {showShowMore && !!restProps.onLoadMore && (
                <tbody>
                  <tr>
                    <td>
                      <ShowMoreWrapper>
                        <Button
                          configuration="text"
                          type="button"
                          onClick={() => {
                            trackShowMore?.();
                            restProps.onLoadMore();
                          }}
                          loading={loading}
                        >
                          {t('common_show_more', {
                            fetchNumber: Math.min(
                              restProps.rowsToLoad,
                              restProps.totalRows - clientSortedRows.length,
                            ),
                            count: restProps.totalRows,
                          })}
                        </Button>
                      </ShowMoreWrapper>
                    </td>
                  </tr>
                </tbody>
              )}
              {footerItems &&
                (isMobile ? (
                  <TableMobileFooter
                    footerItems={singleFooterRow ? ['', ...footerItems] : footerItems}
                    headings={headings}
                  />
                ) : (
                  <TableFooter footerItems={footerItems} singleFooterRow={singleFooterRow} />
                ))}
            </>
          )}
        </Table>
      </TableWrapper>
      {isEmpty && loading && (
        <SpinnerWrapper>
          <Spinner horizontalCenter size="xxl" />
        </SpinnerWrapper>
      )}
      {isEmpty &&
        !loading &&
        (isEmpty && !errorMessage
          ? empty || (
              <EmptyState
                headline={emptyMessage?.title ?? t('empty_analyze_publications-title')}
                description={
                  emptyMessage?.description ?? t('empty_analyze_publications-description')
                }
              />
            )
          : errorMessage && <EmptyState headline={errorMessage} />)}
    </>
  );
};

export default DataTable;
