import type { MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { SIZING_2 } from '../../theme';
import { Button } from '../Button';
import Icons from '../Icons';
import { Text } from '../Text';

type AlertProps = CommonProps & (WithoutIconProps | IconProps);

interface CommonProps {
  action?: {
    text: string;
    loading?: boolean;
  } & (
    | { href?: undefined; onClick: (e: MouseEvent) => void }
    | { href: string; onClick?: (e: MouseEvent) => void }
  );
  children: ReactNode;
}

interface WithoutIconProps {
  type: 'info' | 'warning' | 'error';
  icon?: undefined;
}

interface IconProps {
  type?: 'default';
  icon?: ReactNode;
}

const IconWrapper = styled.div`
  > * {
    width: ${props => props.theme.sizingIconXs};
    height: ${props => props.theme.sizingIconXs};
  }
`;

const Wrapper = styled.aside<{ $type: AlertProps['type'] }>`
  display: grid;
  gap: ${SIZING_2};
  align-items: center;
  max-width: 100%;
  text-align: left;
  grid-template-columns: auto 1fr auto;
  color: ${props => props.theme.colorTextBodyPrimary};
  min-height: ${props => props.theme.sizingAlertDefault};
  border-radius: ${props => props.theme.radiusAlert};
  padding: 0 ${SIZING_2};
  > div {
    padding: ${SIZING_2} 0;
  }
  ${props => {
    switch (props.$type) {
      case 'error':
        return css`
          border: 1px solid ${props.theme.colorBorderAlertError};
          background: ${props.theme.colorBackgroundAlertError};
          ${IconWrapper} {
            color: ${props.theme.colorIconsDanger};
          }
        `;
      case 'info':
        return css`
          border: 1px solid ${props.theme.colorBorderAlertInfo};
          background: ${props.theme.colorBackgroundAlertInfo};
          ${IconWrapper} {
            color: ${props.theme.colorIconsInfo};
          }
        `;
      case 'warning':
        return css`
          border: 1px solid ${props.theme.colorBorderAlertWarning};
          background: ${props.theme.colorBackgroundAlertWarning};
          ${IconWrapper} {
            color: ${props.theme.colorIconsWarning};
          }
        `;
      default:
        return css`
          border: 1px solid ${props.theme.colorBorderAlertDefault};
          background: ${props.theme.colorBackgroundAlertDefault};
          ${IconWrapper} {
            color: ${props.theme.colorIconsDefault};
          }
        `;
    }
  }}
`;

export const Alert = ({ type, icon, action, children }: AlertProps) => {
  if (type === 'error') {
    icon = <Icons.CircleError />;
  } else if (type === 'info') {
    icon = <Icons.CircleInfoFilled />;
  } else if (type === 'warning') {
    icon = <Icons.TriangleWarning />;
  }
  return (
    <Wrapper $type={type}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <Text as="div" variant="body">
        {children}
      </Text>
      {action && (
        <Button
          configuration="text"
          loading={action.loading}
          {...(action.href
            ? {
                href: action.href,
                onClick: action.onClick,
              }
            : {
                type: 'button',
                onClick: action.onClick || (() => {}), // eslint-disable-line @typescript-eslint/no-empty-function
              })}
        >
          {action.text}
        </Button>
      )}
    </Wrapper>
  );
};
