import type React from 'react';
import styled from 'styled-components';
import {
  DESKTOP_BUTTON_HORIZONTAL_PADDING,
  GREY_300,
  GREY_500,
  GREY_800,
  GREY_900,
  SIZING_1,
  SIZING_4,
} from '../../theme';
import { Spinner } from '../Spinner';

const ButtonContainer = styled.button<{
  $iconOnly?: boolean;
  $onDark?: boolean;
}>`
  background: ${({ $onDark, theme }) => ($onDark ? theme.colorBackgroundMiniButtonOnDarkDefault : theme.colorBackgroundMiniButtonOnLightDefault)};
  border-radius: ${({ theme }) => theme.radiusMiniButton};
  border: 0;
  color: ${({ $onDark, theme }) => ($onDark ? theme.colorTextMiniButtonOnDark : theme.colorTextMiniButtonOnLight)};
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  height: ${SIZING_4};
  line-height: 0.875rem;
  padding: ${({ $iconOnly }) => ($iconOnly ? SIZING_1 : `0 ${DESKTOP_BUTTON_HORIZONTAL_PADDING}`)};
  position: relative;
  width: fit-content;
  &:disabled {
    background: ${({ $onDark }) => ($onDark ? GREY_900 : GREY_300)};
    color: ${({ $onDark }) => ($onDark ? GREY_500 : GREY_800)};
    cursor: default;
    pointer-events: none;
  }
  &:hover {
    background: ${({ $onDark, theme }) => ($onDark ? theme.colorBackgroundMiniButtonOnDarkHover : theme.colorBackgroundMiniButtonOnLightHover)};
  }
`;

const Content = styled.span<{ $loading?: boolean }>`
  display: inline-grid;
  grid-auto-flow: column;
  gap: ${SIZING_1};
  align-items: center;
  visibility: ${({ $loading }) => ($loading ? 'hidden' : 'visible')};
`;

const IconWrapper = styled.span`
  width: ${({ theme }) => theme.sizingIconXxs};
  height: ${({ theme }) => theme.sizingIconXxs};
  > * {
    width: 100%;
    height: 100%;
  }
`;

const SpinnerWrapper = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  line-height: 0;
  transform: translate(-50%, -50%);
  > * {
    color: currentColor;
  }
`;

interface CommonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDark?: boolean;
  disabled?: boolean;
  loading?: boolean;
  dataTestId?: string;
  type?: 'button' | 'submit' | 'reset';
}

interface IconOnlyProps extends CommonProps {
  icon: React.ReactNode;
  'aria-label': string;
  children?: never;
  leadingIcon?: never;
}

interface ChildrenProps extends CommonProps {
  children: string | number;
  leadingIcon?: React.ReactNode;
  icon?: never;
}

export const MiniButton = ({
  onClick,
  onDark,
  leadingIcon,
  dataTestId,
  type = 'button',
  icon,
  loading,
  children,
  disabled,
  ...props
}: ChildrenProps | IconOnlyProps) => {
  const iconOnly = !!icon;
  return (
    <ButtonContainer
      onClick={onClick}
      $onDark={onDark}
      $iconOnly={!!iconOnly}
      data-testid={dataTestId}
      type={type}
      disabled={disabled || loading}
      {...props}
    >
      <Content $loading={loading} aria-hidden={loading}>
        {leadingIcon && <IconWrapper>{leadingIcon}</IconWrapper>}
        {iconOnly ? <IconWrapper>{icon}</IconWrapper> : children}
      </Content>
      {loading && (
        <SpinnerWrapper>
          <Spinner size="xs" />
        </SpinnerWrapper>
      )}
    </ButtonContainer>
  );
};
