import { type FC, type ReactElement, useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import styled, { css } from 'styled-components';
import { Select } from '..';
import { GREY_400 } from '../../theme';
import { Card, type CardComponentProps } from '../Card';
import Panel from '../Panel';
import Tab from './Tab';

const Desktop = styled.div<{ $isMobile: boolean }>`
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      height: 0;
      overflow: hidden;
    `}

  ${Card} {
    border-top-left-radius: 0;
    border: 1px solid ${GREY_400};
  }
`;

const Mobile = styled(Panel)<{ $isMobile: boolean }>`
  ${({ $isMobile }) =>
    !$isMobile &&
    css`
      display: none;
    `}

  ${Card} {
    box-shadow: none;
  }
`;

const List = styled.ul`
  align-items: flex-end;
  display: inline-flex;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
`;

const SelectWrapper = styled.div`
  padding-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
  z-index: 1;
`;

type StackedCardsProps = {
  className?: string;
  onChange?: (tab: number) => void;
  cards: {
    tabTitle: string;
    'data-testid'?: string;
    card: ReactElement<CardComponentProps>;
  }[];
};

const StackedCards: FC<StackedCardsProps> = ({ className, cards, onChange }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [delayedSelectedTab, setDelayedSelectedTab] = useState<number>();

  const desktop = useResizeDetector();
  const list = useResizeDetector();

  const desktopWidth = (desktop.width ?? 0) - 10;
  const listWidth = list.width ?? 0;
  const isMobile = desktopWidth < listWidth;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayedSelectedTab(undefined);
    }, 150);
    return () => clearTimeout(timeout);
  }, [delayedSelectedTab, setDelayedSelectedTab]);

  if (!cards) {
    return null;
  }

  const options = cards.map((card, index) => ({
    label: card.tabTitle,
    value: index,
  }));

  return (
    <div className={className}>
      <Desktop ref={desktop.ref} $isMobile={isMobile}>
        <List ref={list.ref}>
          {cards.map((card, index) => (
            <Tab
              data-testid={card['data-testid']}
              key={card.tabTitle}
              title={card.tabTitle}
              selected={index === selectedTab || index === delayedSelectedTab}
              onSetSelected={() => {
                if (onChange) onChange(index);
                setDelayedSelectedTab(selectedTab);
                setSelectedTab(index);
              }}
            />
          ))}
        </List>
        {cards[selectedTab].card}
      </Desktop>

      <Mobile $isMobile={isMobile}>
        <SelectWrapper>
          <Select
            options={options}
            onChange={value => {
              if (!value) return;

              if (onChange) onChange(value);
              setDelayedSelectedTab(value);
              setSelectedTab(value);
            }}
            value={selectedTab}
          />
        </SelectWrapper>
        {cards[selectedTab].card}
      </Mobile>
    </div>
  );
};

export default styled(StackedCards)``;
