import { useCheckNewNavigationDisabled } from '@mnd-frontend/hooks';
import type React from 'react';
import { type ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { GREY_400, SHADOW_2 } from '../../theme';
import { H3 } from '../Typo';
import media from '../utils/media';

export const StyledHeader = styled.div<{
  $noBorder: boolean;
  $sticky: boolean;
  $stuck: boolean;
  $newNavigationDisabled: boolean;
}>`
  border-bottom: 1px solid ${GREY_400};
  padding: 1rem 1.5rem;
  background: white;
  position: ${({ $sticky }) => ($sticky ? 'sticky' : 'relative')};
  top: ${({ $sticky, $newNavigationDisabled }) => {
    if ($sticky) {
      return $newNavigationDisabled ? '-2px' : 'var(--header-height-mobile)';
    }
    return 'var(--header-height-mobile)';
  }};
  ${({ $newNavigationDisabled }) =>
    !$newNavigationDisabled &&
    css`
      ${media.greaterThan('navigationBreakpoint')} {
        top: 0;
      }
    `}
  z-index: ${({ $sticky }) => ($sticky ? 3 : 'auto')};
  box-shadow: ${props => props.$stuck && SHADOW_2};

  & > * {
    margin: 0;
  }

  ${({ $noBorder }) =>
    $noBorder &&
    css`
      border: none;
    `}
`;

type HeaderProps = {
  noBorder?: boolean;
  style?: React.CSSProperties;
  className?: string;
  isSticky?: boolean;
  children: ReactNode;
  customHeader?: boolean;
};

const Header = ({
  style,
  className,
  noBorder = false,
  isSticky = false,
  children,
  customHeader = false,
}: HeaderProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isStuck, setIsStuck] = useState(false);
  const newNavigationDisabled = useCheckNewNavigationDisabled();

  useEffect(() => {
    if (!ref.current || !isSticky) return;

    const observer = new IntersectionObserver(
      ([entry]) => setIsStuck(entry.intersectionRatio < 1),
      { threshold: [1] },
    );
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [isSticky]);

  return (
    <StyledHeader
      ref={ref}
      style={style}
      className={className}
      $noBorder={noBorder}
      $sticky={isSticky}
      $stuck={isStuck}
      $newNavigationDisabled={newNavigationDisabled}
    >
      {customHeader ? children : <H3>{children}</H3>}
    </StyledHeader>
  );
};

export default Header;
