import type React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import { GREY_800, GREY_1000 } from '../../theme';
import { media } from '../utils/media';

const Date = styled.div`
  color: ${GREY_800};
`;

const Title = styled.div`
  color: ${GREY_1000};
  font-weight: 800;
  line-height: 1.5rem;
`;

const Description = styled.div`
  color: ${GREY_800};
  line-height: 1.5rem;
`;

const ItemBodyDiv = styled.div`
  flex-grow: 1;

  ${media.greaterThan('mobile')} {
    min-width: calc(42% - 2rem);
  }
  ${media.greaterThan('phablet')} {
    flex-basis: 0;
    flex-grow: 150;
  }

  & > * {
    flex-grow: 0;
  }

  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
`;

const ItemBody = memo(
  ({
    date,
    title,
    description,
    labelFor,
  }: {
    date?: React.ReactElement;
    title: React.ReactElement | string;
    description?: React.ReactElement | string;
    labelFor?: string;
  }) => (
    <ItemBodyDiv as={labelFor && 'label'} htmlFor={labelFor}>
      {date && <Date>{date}</Date>}
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
    </ItemBodyDiv>
  ),
);

ItemBody.displayName = 'ItemBody';

export default ItemBody;
